import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as medicineActions from "../../Redux/Actions/MedicineActions/MedicineActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import Card from '@material-ui/core/Card';
import Row from 'react-bootstrap/Row';
import TextField from "../../Utils/FormInputs/TextField";
import Label from "../../Utils/FormInputs/Label";
import SelectInput, {CreatableCustomSelect, CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Textarea from "../../Utils/FormInputs/Textarea";
import {useAddMedicine} from "./CustomHooks/useAddMedicine";


const  AddMedicine  = ({actions, match,snackbars,medicines:{categoryList, unitList, typeList}}) => {
    const {product_id} = match.params;
const {state, unit, type_name, category_id, submitted, isSubmitted,
    isLoading, options, price, formRef, handleChangeFields, handleChangeCategory,
    handleChangeType, handleChangeUnit,  handleInputChange, handleChange,
    handleSaveMedicine, handleCloseSnackbar} = useAddMedicine(actions,product_id)
        const {product_name, strength, generic_name, box_size,
            product_location, description,  actual_price, reorder_level,price_type} = state;
        const {open, variant, message} = snackbars;
        const allList = !unitList ? [] : unitList;
        const allCategory = !categoryList ? [] : categoryList;
        const allType = !typeList ? [] : typeList;
        const title = product_id ? 'Edit Medicine' : 'Add Medicine'
        return (
            <div className='journals'>
                <ReusableDashboardHeader component={title} heading="Medicine"
                                         subHeading={title} link={history.location.pathname}/>
                <div className="general-ledger-header">
                    <div className="row">
                        <div className="col-md-3">
                            <h6 className='mt-2 mb-0'>{title}</h6>
                        </div>
                        <div className="col-md-9">
                            <div className="text-right">
                                <Link to="/managemedicine">
                                    <button type="button" className="btn  pharmacy-btn-outline">
                                        <FontAwesomeIcon icon={faList}/> Manage Medicine
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-medicine-card">
                    <Card className="p-3 general-card">
                        <form onSubmit={handleSaveMedicine} autoComplete="off" ref={formRef}>
                            <PharmacySnackbar open={open} variant={variant} message={message}
                                              handleCloseBar={handleCloseSnackbar}/>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label sm={2} column type name='Medicine Name'/>
                                        <Col sm={7}>
                                            <TextField type="text" placeholder="Medicine name" name="product_name"
                                                       value={titleCase(product_name)}
                                                       onChange={handleChangeFields}/>
                                            {(submitted && !product_name) &&
                                            <div className="invalid-text">Medicine name is required</div>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Label sm={2} column type name='Generic Name'/>
                                        <Col sm={7}>
                                            <TextField type="text" placeholder="Generic name" name="generic_name"
                                                       value={titleCase(generic_name)}
                                                       onChange={handleChangeFields}/>
                                            {(submitted && !generic_name) &&
                                            <div className="invalid-text">Generic name is required</div>}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Strength'/>
                                        <Col sm={7}>
                                            <TextField type="text" placeholder="Strength" name="strength"
                                                       value={titleCase(strength)} onChange={handleChangeFields}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6"/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Category' type/>
                                        <Col sm={7}>
                                            <CustomSelect
                                                placeholder="select category"
                                                options={allCategory.map(category => ({
                                                    value: category.category_id,
                                                    label: titleCase(category.category_name)
                                                }))}
                                                value={category_id}
                                                onChange={handleChangeCategory}
                                            />
                                            {(submitted && !category_id) &&
                                            <div className="invalid-text">Category is required</div>}
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Medicine Type' type/>
                                        <Col sm={7}>
                                            <CustomSelect
                                                placeholder="select medicine type"
                                                options={allType.map(type => ({
                                                    value: type.type_id,
                                                    label: titleCase(type.type_name)
                                                }))}
                                                value={type_name}
                                                onChange={handleChangeType}
                                            />
                                            {(submitted && !type_name) &&
                                            <div className="invalid-text">Medicine type is required</div>}
                                        </Col>

                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Unit'/>
                                        <Col sm={7}>
                                            <CustomSelect
                                                placeholder="select unit"
                                                value={unit}
                                                onChange={handleChangeUnit}
                                                options={allList.map(unit => ({
                                                    value: unit.id,
                                                    label: titleCase(unit.unit_name)
                                                }))}

                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Box Size'/>
                                        <Col sm={7}>
                                            <TextField type="text" placeholder="Box size" name="box_size"
                                                       value={titleCase(box_size)} onChange={handleChangeFields}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label name='Details' sm={2} column/>
                                        <Col sm={7}>
                                            <Textarea name="description" value={titleCase(description)}
                                                      onChange={handleChangeFields}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Medicine Shelf'/>
                                        <Col sm={7}>
                                            <TextField type="text" placeholder="Medicine shelf" name="product_location"
                                                       value={titleCase(product_location)}
                                                       onChange={handleChangeFields}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Price Type' type/>
                                        <Col sm={7}>
                                            <SelectInput value={price_type} onChange={handleChangeFields}
                                                         options={[{value: '', text: 'Price Type'},
                                                             {value: '1', text: 'Mark-up price'},
                                                             {value: '0', text: 'Actual price'}]} name='price_type'/>
                                            {(submitted && !price_type) &&
                                            <div className="invalid-text">Price type is required</div>}
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6">
                                    {price_type === "0" ?
                                        <Form.Group as={Row}>
                                            <Label sm={2} column name='Actual Price' type/>
                                            <Col sm={7}>
                                                <TextField type="number" placeholder="Enter actual price"
                                                           onChange={handleChangeFields} value={actual_price} name='actual_price'/>
                                                {(submitted && !actual_price) &&
                                                <div className="invalid-text">Actual price is required</div>}
                                            </Col>
                                        </Form.Group>
                                        : price_type === "1" ?
                                            <Form.Group as={Row}>
                                                <Label sm={2} column name='Mark-up price(%)' type/>
                                                <Col sm={7}>
                                                    <CreatableCustomSelect
                                                        isClearable
                                                        isLoading={isLoading}
                                                        onChange={handleChange}
                                                        onCreateOption={handleInputChange}
                                                        options={options}
                                                        value={price}
                                                    />
                                                    {(submitted && !price) &&
                                                    <div className="invalid-text">Mark-up price is required</div>}
                                                </Col>
                                            </Form.Group>
                                            :
                                            <div/>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group as={Row}>
                                        <Label sm={2} column name='Reorder Level'/>
                                        <Col sm={7}>
                                            <TextField type="number" placeholder="Reorder level" name="reorder_level"
                                                       value={reorder_level}
                                                       onChange={handleChangeFields} defaultValue={0}/>
                                        </Col>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6"/>
                            </div>
                            <button type="submit" disabled={isSubmitted === 'pending'}
                                    className="btn pharmacy-btn px-3">{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>
                        </form>
                    </Card>
                </div>
            </div>
        )

}

function mapStateToProps(state) {
    return {
        medicines: state.medicines,
        snackbars: state.snackbars,
        manufacturers: state.manufacturers
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            medicineActions: bindActionCreators(medicineActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicine);