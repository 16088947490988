import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import DashboardHeader from "../../../Containers/Dasboard/DashboardHeader";


class EditAddEmployee extends Component {

    render() {
        const {addEmployee,editEmployee} = this.props;
        return (
            <div>
            <DashboardHeader hrm addEmployee={addEmployee} editEmployee={editEmployee}/>
            <Card className="styled-table-card p-3">
                <h6>{addEmployee ?" Add Employee": "Edit Employee"}</h6>
                <hr/>
                <Form autoComplete="off">
                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">First Name <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="First Name"/>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Last Name <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="Last Name"/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">Designation <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="4">
                            <Form.Control as="select" name="designation">
                                <option value="value1">HR</option>
                                <option value="value2">Manager</option>
                                <option value="value3">Staff</option>

                            </Form.Control>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Phone <span className="text-danger asterics">*</span></Form.Label>
                        <Col sm="4">
                            <Form.Control type="numbers" placeholder="Phone"/>
                        </Col>

                    </Form.Group>


                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">Rate Type</Form.Label>
                        <Col sm="4">
                            <Form.Control as="select" name="ratetype">
                                <option value="value1">Hourly</option>
                                <option value="value2">Salary</option>
                            </Form.Control>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Hourly Rate/Salary</Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="Hourly Rate/Salary"/>
                        </Col>

                    </Form.Group>


                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">Email</Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="Email"/>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Blood Group</Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="Blood Group"/>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">Address Line 1</Form.Label>
                        <Col sm="4">
                            <Form.Control type="textarea" placeholder="Address Line 1"/>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Address Line 2</Form.Label>
                        <Col sm="4">
                            <Form.Control type="textarea" placeholder="Address Line 2"/>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">Picture</Form.Label>
                        <Col sm="4" >
                            <Form.Control type="file"/>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Country</Form.Label>
                        <Col sm="4">
                            <Form.Control as="select" name="country">
                                <option value="value1">Uganda</option>
                                <option value="value2">United States of America</option>
                                <option value="value3">Tanzania</option>
                                <option value="value4">Kenya</option>
                                <option value="value5">South Africa</option>
                                <option value="value6">Egypt</option>
                            </Form.Control>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="2" className="text-left">City</Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="City"/>
                        </Col>

                        <Form.Label column sm="2" className="text-left">Zip code</Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" placeholder="Zip code"/>
                        </Col>
                    </Form.Group>

                    <div  className="text-center">
                        <button type="submit" className="btn pharmacy-info-btn px-4">Save
                        </button>
                    </div>

                </Form>
            </Card>
            </div>
        );
    }
}

export default EditAddEmployee;