import {useEffect, useRef, useState} from 'react';
import {convertDate, titleCase} from "../../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";
import {useEditPurchaseData, usePurchaseOrderData} from "./usePurchaseUpdateData";


export const useAddPurchase = (actions, ids) => {
    const {local_purchase_order_id, purchase_id} = ids
    const [rows, setRows] = useState([{
        product_name: '', product_id: '',
        batch_id: '', expiry_date: '', total_product: '', selectProduct: false,
        product_quantity: '', product_rate: '', total_price: '', discount: '',
        disableExpiry: true, showProduct: false
    }]);
    const [manufacturer_name, setManufacturerName] = useState('');
    const [state, setState] = useState({
        purchase_date: convertDate(), invoice_no: '', details: '',
        payment_type: 1, bank_id: ''
    });
    const [grand_total, setGrandTotal] = useState(0);
    const [purchaseCsv, setPurchaseCsv] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allPurchases, setAllPurchases] = useState([]);
    const [openSupplier, setOpenSupplier] = useState(false);
    const [submitCsv, setSubmitCsv] = useState(false);
    const [supplier, setSupplier] = useState({manufacturer_name: '', mobile: '', address: '', notes: '',})
    const csvLink = useRef();
    const {purchase, products} = useEditPurchaseData(actions, purchase_id);
    const {orderData,manufacturer}  = usePurchaseOrderData(actions,local_purchase_order_id)

    useEffect(() => {
        actions.manufacturerActions.getAllManufacturers();
    }, [])

    useEffect(() => {
        if (!(purchase_id || local_purchase_order_id)) {
            return;
        }
        if (purchase_id) {
            const {payment_type, invoice_no, purchase_date, details, manufacturer_name, grand_total} = purchase
            setState({purchase_date, invoice_no, details, payment_type})
            setManufacturerName(manufacturer_name)
            setGrandTotal(grand_total)
            setRows(products)
        }
        if (local_purchase_order_id){
            setManufacturerName(manufacturer)
            setRows(orderData)
            calculateGrandTotal(orderData)
        }
    }, [purchase, products,orderData,manufacturer])


    const handleChangeSupplier = (event) => {
        const {name, value} = event.target;
        setSupplier({...supplier, [name]: value})
    }

    const handleChangeValue = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleOpenSupplierDialog = () => {
        setOpenSupplier(true)
    };
    const handleCloseSupplierDialog = () => {
        setOpenSupplier(false)
    };

    const handleSubmitPurchase = event => {
        event.preventDefault();
        const {purchase_date, payment_type, invoice_no, details, bank_id} = state
        setSubmitted(true)
        let formData = new FormData();
        if (purchase_id) {
            formData.append('purchase_id', purchase_id);
        }
        if (local_purchase_order_id) {
            formData.append('local_purchase_order_id', local_purchase_order_id);
        }
        formData.append('manufacturer_id', manufacturer_name.value);
        formData.append('purchase_date', purchase_date);
        formData.append('chalan_no', invoice_no);
        formData.append('purchase_details', details);
        formData.append('paytype', payment_type);
        formData.append('bank_id', bank_id);
        formData.append('total_discount', 0);
        formData.append('grand_total_price', grand_total);
        rows.forEach(row => {
            formData.append('product_id', row.product_id);
            formData.append('product_name', row.product_name);
            formData.append('batch_id', row.batch_id);
            formData.append('expeire_date', row.expiry_date);
            formData.append('product_quantity', row.product_quantity);
            formData.append('product_rate', row.product_rate);
            formData.append('discount', 0);
            formData.append('total_price', row.total_price ? row.total_price : '')
        })
        const arr = rows.every(item => item.product_id && item.batch_id && item.expiry_date && item.product_quantity &&
            item.product_rate && item.expiry_date > purchase_date)
        if (arr && invoice_no && manufacturer_name) {
            setIsSubmitted('pending')
            const url = purchase_id ? 'purchase_update' : 'insert_purchase'
            axios.post(`${config.epharmUrl}/Cpurchase/${url}`, formData)
                .then(() => {
                    actions.snackbarActions.successSnackbar('Purchase Saved Successfully');
                    setIsSubmitted('resolved');
                    history.push('/managepurchase')
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };

    const handleChangeManufacturer = value => {
        setManufacturerName(value);
        setOpenAlert(false)

    };

    const handleChange = (event, index) => {
        let {name, value} = event.target;
        const arr = rows.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setRows(arr)
    };

    const retrieveExpiry = (b_id, idx) => {
        const rowsCopy = [...rows]
        axios.get(`${config.epharmUrl}/Cinvoice/retrieve_product_batch_id`, {
            params: {batch_id: b_id, product_id: rows[idx]['product_id']}
        }).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            if (data) {
                if (dt.expire_date) {
                    rowsCopy[idx]['expiry_date'] = dt.expire_date;
                    rowsCopy[idx]['disableExpiry'] = true;
                } else {
                    rowsCopy[idx]['expiry_date'] = '';
                    rowsCopy[idx]['disableExpiry'] = false;
                }
                setRows(rowsCopy)
            }
        }).catch(error => {
            rowsCopy[idx]['expiry_date'] = '';
            rowsCopy[idx]['disableExpiry'] = false;
            setRows(rowsCopy)
            errorMessages(error, null, actions)
        });

    }


    const handleAddRow = () => {
        const item = {
            product_name: '', product_id: '', batch_id: '', expiry_date: '', total_product: '',
            product_quantity: '', product_rate: '', total_price: '', discount: '', selectProduct: false,
            disableExpiry: true, showProduct: false
        };
        setRows([...rows, item])
    };

    const handleRemoveSpecificRow = (idx) => () => {
        const arr = rows.filter((_, index) => index !== idx)
        calculateGrandTotal(arr);
        setRows(arr)
    };

    const calculateGrandTotal = (arr) => {
        const sum = arr.reduce((a, row) => {
            return +(a + row.total_price);
        }, 0);
        setGrandTotal(sum)
    };

    const handleSearchProduct = (event, index) => {
        const {value} = event.target;
        const rowsCopy = [...rows]
        if (rowsCopy[index]) {
            rows[index]['product_name'] = value;
        }
        rowsCopy[index]['product_id'] = '';
        rowsCopy[index]['batch_id'] = '';
        rowsCopy[index]['expiry_date'] = '';
        rowsCopy[index]['total_product'] = 0;
        rowsCopy[index]['product_quantity'] = ''
        rowsCopy[index]['product_rate'] = '';
        rowsCopy[index]['total_price'] = 0;
        rowsCopy[index]['discount'] = 0;
        rowsCopy[index]['selectProduct'] = false;
        rowsCopy[index]['disableExpiry'] = true;
        let temp = event.target.value.trim();
        if (temp !== '') {
            actions.purchaseActions.searchDrugbyManufacturer(titleCase(temp));
            rowsCopy[index]['selectProduct'] = false;
            rowsCopy[index]['showProduct'] = true;

        } else {
            rowsCopy[index]['showProduct'] = false;
        }
        setRows(rowsCopy)
    };

    const retrieveProductData = (event, index) => {
        const rowsCopy = [...rows]
        if (rowsCopy[index]) {
            rowsCopy[index]['product_name'] = event.innerText;
            rowsCopy[index]['product_id'] = event.value;
            rowsCopy[index]['selectProduct'] = false;
            axios.get(`${config.epharmUrl}/Cinvoice/retrieve_product_data`,
                {params: {product_id: event.value}}).then(response => {
                const data = response.data;
                const dt = data ? data : {};
                rowsCopy[index]['total_product'] = dt.total_product ? dt.total_product : 0;
                setRows(rowsCopy)
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        rowsCopy[index]['showProduct'] = false;
    };

    const getTotalApparent = (index) => {
        rows[index]['total_price'] = +(rows[index]['product_rate'] * rows[index]['product_quantity']);
        calculateGrandTotal(rows)
    };

    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const rowsCopy = [...rows];
        if (rowsCopy[index]) {
            rowsCopy[index]["product_quantity"] = value;
            getTotalApparent(index)
        }
        calculateGrandTotal(rowsCopy)
        setRows(rowsCopy)
    };
    const handleChangePrice = (event, index) => {
        const {value} = event.target;
        const rowsCopy = [...rows];
        if (rowsCopy[index]) {
            rowsCopy[index]["product_rate"] = value;
            getTotalApparent(index)
        }
        calculateGrandTotal(rowsCopy)
        setRows(rowsCopy)
    }

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleUploadCsv = (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append('upload_csv_file', purchaseCsv);
        setSubmitCsv(true)
        if (purchaseCsv) {
            actions.purchaseActions.uploadCsv(formData);
        }

    };

    const handleChangePurchaseCsv = event => {
        if (event.target.files[0].name.substr(event.target.files[0].name.length - 4) === ".csv") {
            setPurchaseCsv(event.target.files[0]);
        } else {
            event.target.value = null;
            actions.snackbarActions.errorSnackbar('Please upload only csv format')
        }

    };

    const fetchData = () => {
        axios.get(`${config.epharmUrl}/Cpurchase/getallpurchases`).then(response => {
            const data = response.data;
            const dt = data ? data : {}
            const list = dt.purchase_list ? dt.purchase_list : [];
            setAllPurchases(list)
            csvLink.current.link.click()
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const saveNewManufacturer = event => {
        event.preventDefault();
        const {manufacturer_name, address, mobile, notes} = supplier;
        axios.post(`${config.epharmUrl}/Cmanufacturer/insert_manufacturer`, null,
            {params: {manufacturer_name, address, mobile, details: notes}}).then(() => {
            actions.snackbarActions.successSnackbar('Supplier Saved Successfully');
            actions.manufacturerActions.getAllManufacturers();
        }).catch(error => {
            errorMessages(error, null, actions)
        });
        setOpenDialog(false)
    };
    return {
        rows, manufacturer_name, state, grand_total, purchaseCsv, openAlert, openDialog, submitted,
        isSubmitted, allPurchases, openSupplier, submitCsv, supplier, handleChangeSupplier, handleChangeValue,
        handleOpenSupplierDialog, handleCloseSupplierDialog, handleSubmitPurchase, handleChangeManufacturer,
        handleChange, retrieveExpiry, handleAddRow, handleRemoveSpecificRow, handleSearchProduct, retrieveProductData,
        handleChangeQuantity, handleChangePrice, handleCloseBar, handleOpenDialog, handleCloseDialog, handleUploadCsv,
        handleChangePurchaseCsv, fetchData, saveNewManufacturer, csvLink
    }
}