import React from "react"
import Form from "react-bootstrap/Form";
import {useTheme} from "@material-ui/styles";


const TextField = ({type,value,name,onChange, disabled, ...rest}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <Form.Control  type={type} value={value} name={name} onChange={(e)=>onChange(e)}
                       className={`form__control ${palette.type === "dark" && 'form-control-dark'}`} disabled={disabled} {...rest}/>
    )
};
export default TextField;
