import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ProductstrendsGraph from "./ProductstrendsGraph";
import {titleCase} from "../../Utils/titleCaseFunction";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import axios from "axios";
import config from "../../Helpers/config.json";
import {usePeriodsFilter} from "./CustomHooks/usePeriodsFilter";
import {ReusableSearchForm} from "./ReusableSearchForm";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";


function ProductTrends(props) {
    const [sort, setSort] = useState('amount')
    const [product, setProduct] = useState('')
    const [showProducts, setShowProducts] = useState(false)
    const [productId, setProductId] = useState('')
    const [productData, setProductData] = useState([])
    const [trendsData, setTrendsData] = useState([])
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const {period, from_date, to_date, handleChangeDate,
        year, inputTypes, handleChangePeriod, handleChangeYear
    } = usePeriodsFilter()
    const {drugs} = props.invoices;

    const handleClickSort = (e) => {
        const {value} = e.target
        setSort(value)
    }

    useEffect(() => {
        if (sort === 'amount' && productData.length > 0) {
            let _amountsData = productData.map(({amount, date}) => ({amount, date}))
            setTrendsData(_amountsData)
        } else if (sort === 'quantity' && productData.length > 0) {
            let _amountsData = productData.map(({quantity, date}) => ({quantity, date}))
            setTrendsData(_amountsData)
        }
    }, [productData, sort])

    const handleRetrieveProduct = (event) => {
        const {actions} = props;
        setProduct(event.target.value)
        let product_value = event.target.value.trim();
        if (product_value !== '') {
            setShowProducts(true)
            actions.invoiceActions.getDrugs(titleCase(product_value));
        }
    };

    const handleClickDrug = (drug) => {
        setProductId(drug.value)
        setProduct(drug.label)
        setShowProducts(false)
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append('product_id', productId)
        formData.append('start_date', period.value === 'yearly' ? year : from_date)
        formData.append('end_date', period.value === 'weekly' ? from_date : to_date)
        setSubmitted(true)
        if (((period.value === 'custom' && from_date && to_date) || (period.value !== 'custom' &&
            (from_date || year))) && productId) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Cproduct/product_trend_details`, formData).then(res => {
                const _data = res.data
                const _trends = !_data ? [] : _data.data
                const _trendsWithNewDate = _trends.map(({quantity, amount, date}) => {
                    function dateToYMD(date) {
                        let strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                        let daysArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
                        let _date
                        switch (date) {
                            case "January":
                                _date = strArray[0]
                                break
                            case "February":
                                _date = strArray[1]
                                break
                            case "March":
                                _date = strArray[2]
                                break
                            case "April":
                                _date = strArray[3]
                                break
                            case "May":
                                _date = strArray[4]
                                break
                            case "June":
                                _date = strArray[5]
                                break
                            case "July":
                                _date = strArray[6]
                                break
                            case "August":
                                _date = strArray[7]
                                break
                            case "September":
                                _date = strArray[8]
                                break
                            case "October":
                                _date = strArray[9]
                                break
                            case "November":
                                _date = strArray[10]
                                break
                            case "December":
                                _date = strArray[11]
                                break
                            default:
                                const _d = new Date(date)
                                let d = _d.getDate();
                                let _day = daysArray[_d.getDay()]
                                let m = strArray[_d.getMonth()];
                                //let y = _d.getFullYear();
                                if (period === 'week') {
                                    _date = '' + _day + ' ' + (d <= 9 ? '0' + d : d);
                                } else {
                                    _date = '' + (d <= 9 ? '0' + d : d) + ' ' + m;
                                }
                        }
                        return _date

                    }

                    return {quantity, amount, date: dateToYMD(date)}

                })
                setProductData(_trendsWithNewDate)
                if (sort === 'amount' && _trendsWithNewDate.length > 0) {
                    let _amountsData = _trendsWithNewDate.map(({amount, date}) => {

                        return {amount, date}
                    })

                    setTrendsData(_amountsData)
                } else if (sort === 'quantity' && _trendsWithNewDate.length > 0) {
                    let _amountsData = _trendsWithNewDate.map(({quantity, date}) => ({quantity, date}))
                    setTrendsData(_amountsData)
                }
                setIsSubmitted('resolved')
            }).catch(error => {
                console.log(error)
                setIsSubmitted('rejected')
            })
        }
    }
    const periods = [{value: 'today', label: 'Today'}, {value: 'weekly', label: 'Weekly'},
        {value: 'monthly', label: 'Monthly'}, {value: 'yearly', label: 'Yearly'},
        {value: 'custom', label: 'Custom'}]
    return (
        <div className='journals'>
            {/*<DashboardHeader report addPurchase/>*/}
            <div className='general-ledger-header'>
                <form onSubmit={handleSubmit} style={{marginBottom: '-1%'}}>
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group as={Row}>
                                <Label name='Product' column sm={3} type/>
                                <Col sm={9}>
                                <TextField type='text' value={titleCase(product)} onChange={handleRetrieveProduct}/>
                                {showProducts &&
                                <Form.Control as="select" multiple className='product-drop-down'>
                                    {drugs.map((drug, index) => (
                                        <option key={index}
                                                value={drug.value}
                                                onClick={() => handleClickDrug(drug)}>{titleCase(drug.label)}</option>))}
                                </Form.Control>}
                                {submitted && !productId ?
                                    <div className='invalid-text'>Product is required</div> : null}
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="col-md-8">
                            <ReusableSearchForm handleChangePeriod={handleChangePeriod} period={period}
                                                inputType={inputTypes} from_date={from_date} year={year}
                                                to_date={to_date} periods={periods}
                                                handleChangeYear={handleChangeYear} handleChangeDate={handleChangeDate}
                                                isSubmitted={isSubmitted} submitted={submitted}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <ProductstrendsGraph product={productId} products_data={trendsData} sort={sort}
                                 handleClickSort={handleClickSort} to_date={to_date} from_date={from_date}/>
        </div>
    );

}


function mapStateToProps(state) {
    return {invoices: state.invoices}
}

function mapDispatchToProps(dispatch) {
    return {actions: {invoiceActions: bindActionCreators(invoiceActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTrends);
