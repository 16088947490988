import React from 'react';
import {EnhancedTableHead, EnhancedTableToolbar, MuiPagination, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {useMuiPagination} from "../../Containers/Dasboard/DashboardTables";
import FilterResults from "react-filter-search";
import {titleCase} from "../../Utils/titleCaseFunction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";


export const MedicineDetailsReport = ({term, data, loading, headData, urls, title, productDetails}) => {
    const {page, rowsPerPage, handleChangeRowsPerPage, handleChangePage} = useMuiPagination();
    const {invoice_url, details_url} = urls;
    return (
        <div>
            <div className='general-ledger-header'>
                <div className="pos-buttons text-right">
                    <Link to="/addmedicine" style={{textDecoration: "none", color: "inherit"}}>
                        <button type="button" className="btn mr-2 pharmacy-info-btn">
                            <FontAwesomeIcon icon={faPlus}/> Add Medicine
                        </button>
                    </Link>
                    <Link to="/managemedicine">
                        <button type="button" className="btn pharmacy-primary-btn mr-2">
                            <FontAwesomeIcon icon={faList} className="mr-1"/>Manage Medicine
                        </button>
                    </Link>
                </div>
            </div>

            <div className=" mt-2 px-2">
                <div className="mt-2">
                    <h6>Medicine Name: <span>{productDetails && productDetails.product_name}</span></h6>
                    <h6>Medicine Type: <span>{productDetails && productDetails.product_model}</span></h6>
                    <h6>Price: <span>{productDetails && productDetails.price}</span></h6>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <p>Total Purchase = {productDetails && productDetails.total_purchase}</p>
                    </div>
                    <div className="col-md-4">
                        <p>Total Sale = {productDetails && productDetails.total_sales}</p>
                    </div>
                    <div className="col-md-4">
                        <p>Stock = {productDetails && productDetails.stock}</p>
                    </div>
                </div>

            </div>

            <MuiPaper>
                <EnhancedTableToolbar title={title}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="7" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : data.length > 0 ?
                            <FilterResults
                                value={term}
                                data={data}
                                renderResults={results => (
                                    <TableBody>
                                        {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((d, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row" padding="default"
                                                                   className='mui-table-cell'>
                                                            <span>{d.date}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                                <span>
                                                                    {d.invoice}
                                                                </span>
                                                        </TableCell>
                                                        <TableCell>
                                                                <span><Link
                                                                    to={{pathname: `/${invoice_url}/${d.invoice_id}`}}
                                                                    style={{color: "#09905c"}}>{d.invoice_id}</Link></span>
                                                        </TableCell>
                                                        <TableCell>
                                                                <span><Link
                                                                    to={{pathname: `/${details_url}/${d.id}`}}
                                                                    style={{color: "#09905c"}}>{titleCase(d.name)}</Link></span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.quantity}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.rate}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.total_amount}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                )}
                            />
                            : <TableBody><TableRow>
                                <TableCell colSpan="7" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <MuiPagination data={data} rowsPerPage={rowsPerPage} page={page}
                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                               handleChangePage={handleChangePage} rows={[10, 20, 30, 50, 100]} colSpan={2}/>
            </MuiPaper>
        </div>
    )
}