import React, {useEffect, useState} from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions"
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import config from "../../Helpers/config";
import axios from "axios"
import {convDate, convertDate, titleCase} from "../../Utils/titleCaseFunction";
import {history} from "../../Helpers/history"
import {errorMessages} from "../../Helpers/ErrorMessages";
import Dialog from "@material-ui/core/Dialog";
import {DialogActions, DialogContent, DialogTitle} from "../../Utils/Dialog/pharmacyDialog";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Grid from "@material-ui/core/Grid";



const AddPurchaseOrder = ({actions, snackbars, manufacturers, purchases, match}) => {
    const {local_purchase_order_id, status, template} = match.params
    const [rows, setRows] = useState([{
        product_name: '', product_id: '',
        quantity: '', rate: '', total: '', showProduct: false
    }]);
    const [manufacturer_name, setManufacturerName] = useState('');
    const [state, setState] = useState({
        deliver_to: '', delivery_date: '', order_date: convertDate(),
        comments: '', reason: '',purchase_order_number:''
    });
    const [grand_total, setGrandTotal] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [id, setId] = useState('');
    const [total_tax, setTotalTax] = useState(0);
    const [sub_total, setSubTotal] = useState(0);
    const [purchaseOrder, setPurchaseOrder] = useState({})


    useEffect(() => {
        actions.manufacturerActions.getAllManufacturers();
    }, [actions.manufacturerActions])

    useEffect(() => {
        if (!local_purchase_order_id) {
            return
        }
        axios.get(config.epharmUrl + '/Cpurchaseorder/purchaseoder_update_form', {params: {local_purchase_order_id}})
            .then(response => {
                const data = response.data;
                const dt = data ? data : {}
                const products = dt.product_list ? dt.product_list : [];
                setPurchaseOrder(dt)
                calculateGrandTotal(products)
            }).catch(error => {
            errorMessages(error, null, actions);
        })

    }, [actions,  local_purchase_order_id])

    useEffect(() => {
        if (!local_purchase_order_id) {
            return
        }
        const products = !purchaseOrder.product_list ? [] : purchaseOrder.product_list;
        setRows(products)
        setManufacturerName({value: purchaseOrder.manufacturer_id, label: purchaseOrder.manufacturer_name})
        setState({
            deliver_to: purchaseOrder.ship_to_address,
            delivery_date: purchaseOrder.delivery_date ? purchaseOrder.delivery_date : '',
            comments: purchaseOrder.details,
            order_date: purchaseOrder.order_date,
            reason: purchaseOrder.reason ? purchaseOrder.reason : '',
            purchase_order_number: purchaseOrder.purchase_order_number ?? ''
        })
        setGrandTotal(purchaseOrder.grand_total);
        setTotalTax(purchaseOrder.tax)

    }, [local_purchase_order_id, purchaseOrder])


    const handleInput = event => {
        const {name, value} = event.target;
        setState({...state,[name]: value});
    };


    const handleSubmitPurchase = event => {
        event.preventDefault();
        const {delivery_date,  deliver_to, comments,purchase_order_number} = state
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_id = !singleUser.user_id ? '' : singleUser.user_id
        let formData = new FormData();
        if (local_purchase_order_id && !template) {
            formData.append('local_purchase_order_id', local_purchase_order_id);
            formData.append('purchase_order_number', purchase_order_number);
        }
        formData.append('manufacturer_id', manufacturer_name.value);
        formData.append('ship_to_address', deliver_to ? deliver_to : '');
        // formData.append('shipping_termes', shipping_terms);
        // formData.append('fob', fob);
        formData.append('delivery_date', delivery_date ? convDate(delivery_date) : delivery_date);
        formData.append('tax', total_tax ? total_tax : 0);
        formData.append('grand_total', grand_total);
        formData.append('comments', comments ? comments : '');
        if (status === '0'|| status === '2'){
            formData.append('status', +status);
        }
        for (let row in rows) {
            if (rows.hasOwnProperty(row)) {
                formData.append('product_id', rows[row].product_id);
                formData.append('quantity', rows[row].quantity);
                formData.append('rate', rows[row].rate);
                formData.append('total', rows[row].total);
            }
        }
        setSubmitted(true)
        const arr = rows.every(item => item.rate && item.product_name && item.quantity)
        if (arr) {
            setIsSubmitted('pending')
            const url = local_purchase_order_id && !template ? 'purchaseoder_update' : 'creat_purchaseorder'
            axios.post(`${config.epharmUrl}/Cpurchaseorder/${url}`, formData, {headers: {user_id}})
                .then(response => {
                    const data = response.data;
                    const dt = data ?? {};
                    const id = dt.local_purchase_order_id ?? '';
                    actions.snackbarActions.successSnackbar(`Purchase Order ${local_purchase_order_id && !template ? 'Edited' : 'Saved'} Successfully`);
                    handleOpenDialog(id)
                    setIsSubmitted('resolved')
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };

    const handleChangeManufacturer = value => {
        setManufacturerName(value)
    };

    // const handleChange = (event, index) => {
    //     const {name, value} = event.target;
    //     const arr = rows.map((item, idx) => {
    //         if (idx === index) {
    //             return {...item, [name]: value}
    //         }
    //         return item
    //     })
    //     setRows(arr)
    // };


    const handleAddRow = () => {
        const item = {product_name: '', product_id: '', quantity: '', unit_price: '', total: '', rate: ''};
        setRows([...rows, item])
    };


    const handleRemoveSpecificRow = (idx) => {
        const arr = rows.filter((_, index) => index !== idx)
        calculateGrandTotal(arr);
        setRows(arr)
    };

    const handleChangeTax = (event, arr) => {
        setTotalTax(event.target.value);
        calculateGrandTotal(arr)
    };

    const calculateTax = () => {
        const tax = !total_tax ? 0 : total_tax;
        return tax / 100;
    };

    const calculateGrandTotal = (arr) => {
        const sum = arr.reduce((a, row) => {
            return +(a + row.total);
        }, 0);
        const tax_amount = calculateTax() * sum;
        const total_amount = sum + tax_amount;
        setSubTotal(sum);
        setGrandTotal(total_amount)
    };


    const handleSearchProduct = (event, index) => {
        let {value} = event.target;
        const items = [...rows]
        if (items[index]) {
            items[index]['product_name'] = value;
            let temp = value.trim();
            if (temp !== ' ') {
                actions.purchaseActions.searchDrugbyManufacturer(titleCase(temp));
                items[index]['showProduct'] = true;
            } else {
                items[index]['showProduct'] = false;
            }
        }
        setRows(items)

    };

    const retrieveProductData = (event, index) => {
        let items = [...rows]
        if (items[index]) {
            items[index]['product_name'] = event.innerText;
            items[index]['product_id'] = event.value;
            items[index]['showProduct'] = false;
        }
        setRows(items)
    };


    const getTotalApparent = (index) => {
        const items = [...rows]
        items[index]['total'] = +(items[index]['rate'] * items[index]['quantity']);
        calculateGrandTotal(items)
    };

    const handleChangeQuantity = (event, index) => {
        const {value} = event.target;
        const items = [...rows]
        if (items[index]) {
            items[index]["quantity"] = value;
            getTotalApparent(index)
        }
        setRows(items)
        calculateGrandTotal(items)
    };

    const handleChangePrice = (event, index) => {
        const {value} = event.target;
        const items = [...rows]
        if (items[index]) {
            items[index]["rate"] = value;
            getTotalApparent(index)

        }
        setRows(items)
        calculateGrandTotal(items)

    };
    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleOpenDialog = (local_order_id) => {
        setOpenDialog(true)
        setId(local_order_id)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };
    const viewOrder = () => {
        history.push(`/purchaseorderapproval/${local_purchase_order_id ? local_purchase_order_id : id}/${status ? status : 0}`)
    };


    const {all_manufacturers} = manufacturers;
    const manufs = !all_manufacturers ? [] : all_manufacturers;
    const {allProducts} = purchases;
    const manufProducts = !allProducts ? [] : allProducts;
    const {open, message, variant} = snackbars;
    const {delivery_date,  deliver_to, comments, reason} = state
    return (
        <div className="journals">
            <ReusableDashboardHeader
                component={`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}
                heading={`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}
                subHeading={`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}
                link={{
                    pathname: local_purchase_order_id && !template ? `/editpurchaseorder/${local_purchase_order_id}/${status}` :
                        local_purchase_order_id && template ? `/editpurchaseordertemplate/${local_purchase_order_id}/${status}/${template}` : `/addpurchaseorder`
                }}/>
            <div className="general-ledger-header">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        <h6>{`${local_purchase_order_id && !template ? 'Edit' : 'New'} Purchase Order`}</h6>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            <Link to='/managepurchaseorder'>
                                <button className='btn btn-sm pharmacy-info-btn'>All Purchase Orders</button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <PharmacySnackbar open={open} message={message} variant={variant}
                              handleCloseBar={handleCloseBar}/>

            <Dialog open={openDialog}>
                <DialogTitle onClose={handleCloseDialog}>
                    <div className="text-center">
                        View Purchase Order
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="text-center">
                        <p style={{fontSize: "14px"}}>Would you like to view the purchase order?</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="text-right">
                        <button onClick={viewOrder} type="button" className="btn pharmacy-info-btn mr-1">View Purchase Order
                        </button>
                        <Link to="/managepurchaseorder">
                            <button type="submit" className="btn pharmacy-grey-btn mr-3">No</button>
                        </Link>

                    </div>
                </DialogActions>
            </Dialog>
            <div className="mui-tables">
                <Card className='p-3'>
                    <form onSubmit={handleSubmitPurchase} autoComplete="off">
                        <Form.Group as={Row}>
                            <Label name='Supplier' type sm={2} column/>
                            <Col sm={4}>
                                <CustomSelect value={manufacturer_name} onChange={handleChangeManufacturer}
                                              options={manufs.map(manu => ({
                                                  value: manu.manufacturer_id,
                                                  label: titleCase(manu.manufacturer_name)
                                              }))} placeholder='Select supplier...'/>
                            </Col>
                        </Form.Group>
                        {/*<Form.Group as={Row}>*/}
                        {/*    <Label name='Purchase Order#' type sm={2} column/>*/}
                        {/*    <Col sm={4}>*/}
                        {/*        <TextField onChange={this.handleInput} value={purchase_order_number} type='text'*/}
                        {/*                   name='purchase_order_number'/>*/}
                        {/*    </Col>*/}
                        {/*</Form.Group>*/}
                        <Form.Group as={Row}>
                            <Label name='Deliver To' type sm={2} column/>
                            <Col sm={4}>
                                <Textarea value={deliver_to} name='deliver_to'
                                          onChange={handleInput}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Label name='Expected Delivery Date' sm={2} column/>
                            <Col sm={4}>
                                <TextField onChange={handleInput}
                                           value={delivery_date ? convDate(delivery_date) : delivery_date} type='date'
                                           name='delivery_date'/>
                            </Col>
                        </Form.Group>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Label name='Comments &amp; Special Instructions' sm={4} column/>
                                    <Col sm={8}>
                                        <Textarea value={comments} name='comments' onChange={handleInput}/>
                                    </Col>
                                </Form.Group>
                            </div>
                            {status === '2' ? <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Label name='Reason' sm={2} column/>
                                    <Col sm={10}>
                                        <Textarea value={reason} name='comments' readOnly/>
                                    </Col>
                                </Form.Group>
                            </div> : null}
                        </div>

                        <table className='table table-sm table-bordered add-purchase-order'>
                            <thead>
                            <tr>
                                <th>Item Details</th>
                                {/*<th>Account</th>*/}
                                <th>Quantity<span
                                    className="text-danger asterics ml-1">*</span></th>
                                <th>Rate<span
                                    className="text-danger asterics ml-1">*</span></th>
                                <th>Amount</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((item, idx) => (
                                <tr id="addr1" key={idx}>
                                    <td>
                                        <TextField type="text"
                                                   value={titleCase(item.product_name)}
                                                   onChange={(event) => handleSearchProduct(event, idx)}
                                        />
                                        {item.showProduct ? <Form.Control as="select" multiple className='drop-down'>
                                            {manufProducts.map((product, index) =>
                                                <option key={index} value={product.value}
                                                        onClick={() => retrieveProductData({
                                                            value: product.value,
                                                            innerText: product.label
                                                        }, idx)}>{product.label}</option>)}
                                        </Form.Control> : null}
                                        {(submitted && !item.product_name) &&
                                        <div className="invalid-text">Please select an item</div>}
                                    </td>
                                    <td>
                                        <TextField
                                            type='number'
                                            name="quantity"
                                            value={item.quantity}
                                            onChange={(e) => handleChangeQuantity(e, idx)}
                                            placeholder="0.00"
                                        />
                                        {(submitted && !item.quantity) &&
                                        <div className="invalid-text">Please enter the quantity</div>}
                                    </td>
                                    <td>
                                        <TextField type="number"
                                                   name="rate"
                                                   value={item.rate}
                                                   onChange={(e) => handleChangePrice(e, idx)}
                                                   placeholder="0.00"
                                        />
                                        {(submitted && !item.rate) &&
                                        <div className="invalid-text">Please enter the rate</div>}
                                    </td>
                                    <td>{rows[idx].total}</td>
                                    <td>
                                        <button type="button" className="btn pharmacy-btn-dismiss btn-sm"
                                                onClick={() => handleRemoveSpecificRow(idx)}>X
                                        </button>
                                    </td>
                                </tr>
                            ))
                            }
                            </tbody>
                        </table>
                        <table className='table table-sm table-borderless'>
                            <tr>
                                <td rowSpan={4} colSpan={2}>
                                    <button type='button' onClick={handleAddRow}
                                            className='btn btn-sm pharmacy-grey-btn'>Add another line
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td/>
                                <td/>
                                <td align='right'>Sub Total</td>
                                <td align='center'><TextField type="number" disabled={true} name='sub_total'
                                                              value={sub_total} style={{width: '50%'}}/></td>
                            </tr>
                            <tr>
                                <td/>
                                <td/>
                                <td align='right'>Tax %</td>
                                <td align='center'><TextField type="number" value={total_tax} name="total_tax"
                                                              onChange={(event) =>
                                                                  handleChangeTax(event, rows)}
                                                              style={{width: '50%'}}/></td>
                            </tr>
                            <tr>
                                <td/>
                                <td/>
                                <td align='right'>Grand Total</td>
                                <td align='center'><TextField type="number" readOnly name='grand_total'
                                                              value={grand_total} style={{width: '50%'}}/></td>
                            </tr>
                        </table>
                        <button className="btn pharmacy-btn px-3" disabled={isSubmitted === 'pending'}
                                type='submit'>{isSubmitted === 'pending' ? "Submitting..." : "Submit"}</button>

                    </form>
                </Card>

            </div>
        </div>
    );

}

function mapStateToProps(state) {

    return {
        manufacturers: state.manufacturers,
        invoices: state.invoices,
        purchases: state.purchases,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch),
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            purchaseActions: bindActionCreators(purchaseActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchaseOrder);