import React, {useRef, useState} from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import config from "../../Helpers/config";
import axios from "axios"
import {convertDate, titleCase} from "../../Utils/titleCaseFunction";
import CsvDialog from "../../Utils/CsvDialog";
import {history} from "../../Helpers/history"
import {errorMessages} from "../../Helpers/ErrorMessages";
import {CSVLink} from "react-csv";
import Row from 'react-bootstrap/Row'
import Card from '@material-ui/core/Card';
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import SelectInput from "../../Utils/FormInputs/SelectInput";

const StockAdjustment = ({actions, snackbars}) => {
    const [rows, setRows] = useState([{
        product_name: '', product_id: '', available_stock: '', batches: [],
        adjustment_quantity: '', adjustment_type: '', total_stock: '', batch_id: '',
        showProduct: false
    }]);
    const [purchaseDate, setPurchaseDate] = useState(convertDate);
    const [stockCsv, setStockCsv] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allStock, setAllStock] = useState([])
    const [drugs, setDrugs] = useState([])
    const csvLink = useRef();

    const handleInput = event => {
        const {value} = event.target;
        setPurchaseDate(value)
    };

    const calculateStock = (arr,index) => {
        const allRows = [...arr];
        if (allRows[index].adjustment_type === 'I') {
            allRows[index]['total_stock'] = +(allRows[index]['available_stock']) + +(allRows[index]['adjustment_quantity'])
        } else if (allRows[index].adjustment_type === 'D' && allRows[index]['available_stock'] > allRows[index]['adjustment_quantity']) {
            allRows[index]['total_stock'] = +(allRows[index]['available_stock']) - +(allRows[index]['adjustment_quantity'])
        } else {
            allRows[index]['total_stock'] = 0;
        }
        setRows(allRows)
    };

    const handleChangeQuantity = (event, index) => {
        const arr = rows.map((item, idx) => {
            if (index === idx) {
                return {...item, adjustment_quantity: event.target.value}
            }
            return item;
        })
        calculateStock(arr,index)
        setRows(arr)
    };

    const handleChangeAdjustmentType = (event, index) => {
        const arr = rows.map((item, idx) => {
            if (index === idx) {
                return {...item, adjustment_type: event.target.value}
            }
            return item;
        })
        calculateStock(arr,index)
        setRows(arr)
    };

    const handleAddRow = () => {
        const item = {
            product_name: '', product_id: '', available_stock: '', batches: [],
            adjustment_quantity: '', adjustment_type: '', total_stock: '', batch_id: '',
            showProduct: false
        };
        setRows([...rows, item])
    };

    const handleRemoveSpecificRow = (idx) => {
        const arr = rows.filter((_, index) => index !== idx);
        setRows(arr)
    };

    const handleChangeBatch = (event, idx) => {
        let allRows = [...rows];
        allRows[idx]['batch_id'] = event.target.value;
        axios.get(`${config.epharmUrl}/Cinvoice/retrieve_product_batch_id`, {
            params: {
                batch_id: event.target.value,
                product_id: allRows[idx]['product_id']
            }
        }).then(response => {
            const data = response.data;
            const dt = data ?? {};
            const prod = dt.total_product ?? 0;
            allRows[idx]['available_stock'] = prod < 0 ? 0 : prod;
            setRows(allRows)
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    const handleRetrieveDrugs = (event, index) => {
        const allRows = [...rows]
        allRows[index]['product_name'] = event.target.value;
        let tmp = event.target.value.trim();
        if (tmp !== '') {
            axios.get(`${config.epharmUrl}/Cinvoice/autocompleteproductsearch`, {params: {product_name: titleCase(tmp)}}).then(response => {
                const drugs = response.data;
                const dt = !drugs ? [] : drugs;
                setDrugs(dt)
            }).catch(error => {
                errorMessages(error, null, actions)
            })
        }
        allRows[index]['showProduct'] = true
        setRows(allRows)
    };

    const handleClickDrug = (event, index) => {
        const allRows = [...rows];
        allRows[index]['product_name'] = event.innerText;
        allRows[index]['product_id'] = event.value;
        axios.post(`${config.epharmUrl}/Cinvoice/retrieve_product_data_inv`, null,
            {params: {product_id: event.value}}).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            allRows[index].batches = !dt.batch ? [] : dt.batch;
            setRows(allRows)
        }).catch(error => {
            errorMessages(error, null, actions)
        });
        allRows[index]['showProduct'] = false
        // setRows(allRows);
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const handleOpenDialog = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const handleUploadCsv = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('upload_csv_file', stockCsv ? stockCsv : '');
        axios.post(`${config.epharmUrl}/Creport/upload_adjustment_CSV`, formData)
            .then(() => {
                actions.snackbarActions.successSnackbar('Stock csv uploaded Successfully');
                setTimeout(() => {
                    history.push('/stockreport')
                }, 2000);
            }).catch(error => {
            errorMessages(error, null, actions);
        })
    };

    const handleChangePurchaseCsv = event => {
        if (event.target.files[0].name.substr(event.target.files[0].name.length - 4) === ".csv") {
            setStockCsv(event.target.files[0])
        } else {
            event.target.value = null;
            actions.snackbarActions.errorSnackbar('Please upload only csv format')
        }

    };

    const handleSubmit = event => {
        event.preventDefault();
        const formData = new FormData();
        const user = JSON.parse(sessionStorage.getItem('user'));
        const singleUser = !user ? {} : user;
        const user_id = !singleUser.user_id ? "" : singleUser.user_id;
        formData.append('user_id', user_id);
        setSubmitted(true)
        rows.forEach(row => {
            formData.append('product_id', row.product_id);
            formData.append('quantity', row.adjustment_quantity);
            formData.append('available_quantity', row.available_stock);
            formData.append('adjustment_type', row.adjustment_type);
            formData.append('batch_id', row.batch_id);
        });

        const arr = rows.every(item => item.product_name && item.adjustment_quantity && item.adjustment_type && item.batch_id);
        const adjust = rows.every(item => (item.adjustment_type === 'D' && item.available_stock >= item.adjustment_quantity) ||
            (item.adjustment_type === 'I' && (item.available_stock >= item.adjustment_quantity || item.adjustment_quantity >= item.available_stock)))
        if (arr && adjust) {
            setIsSubmitted('pending')
            axios.post(`${config.epharmUrl}/Creport/save_adjustment`, formData)
                .then(() => {
                    actions.snackbarActions.successSnackbar('Stock adjusted Successfully');
                    history.push('/stockreport')
                    setIsSubmitted('resolved')
                }).catch(error => {
                errorMessages(error, null, actions);
                setIsSubmitted('rejected')
            })
        }
    };


    const fetchData = () => {
        axios.get(`${config.epharmUrl}/Creport/getStock`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const stock_list = !dt.stock ? [] : dt.stock
            setAllStock(stock_list)
            csvLink.current.link.click()
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };


    const allDrugs = !drugs ? [] : drugs;
    const {open, message, variant} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const user_name = !singleUser.user_name ? "" : singleUser.user_name;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component='Stock Adjustment' heading="Stock"
                                     subHeading='Stock Adjustment' link={history.location.pathname}/>
            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-md-6">
                        <h6 className='mt-2 mb-0'>Stock Adjustment</h6>
                    </div>
                    <div className="col-md-6">
                        <div className="text-right">
                            <button className="btn pharmacy-info-btn mx-3 btn-sm"
                                    onClick={handleOpenDialog}>Upload Stock CSV
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <CsvDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} stock
                       handleUploadCsv={handleUploadCsv} handleChangeUpload={handleChangePurchaseCsv}
                       message="Stock">
                    <span>
                        <button onClick={fetchData}
                                className="btn pharmacy-primary-btn btn-sm">Download Sample File</button>
                    <CSVLink
                        data={allStock}
                        ref={csvLink}
                        filename="StockAdjustment.csv"
                        className="hidden"
                    />
                    </span>
            </CsvDialog>
            <Card className="p-2">
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={handleCloseBar}/>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group as={Row}>
                                <Label column sm="2" name='Date'/>
                                <Col sm="8">
                                    <TextField name="purchase_date" value={purchaseDate} type="date"
                                               onChange={handleInput}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Label column sm="2" name=' Adjusted by'/>
                                <Col sm="8">
                                    <TextField value={user_name} type="text" readOnly/>
                                </Col>
                            </Form.Group>
                        </div>
                        <div className="col-md-6"/>
                    </div>
                    <div className="table-responsive mt-1">
                        <table className="table table-bordered p-0 table-sm">
                            <thead>
                            <tr>
                                <th>Product<span className="text-danger asterics ml-1">*</span></th>
                                <th>Batch ID<span className="text-danger asterics ml-1">*</span></th>
                                <th width="100px">Stock</th>
                                <th>Quantity<span className="text-danger asterics ml-1">*</span></th>
                                <th>Adjustment Type <span className="text-danger asterics ml-1">*</span></th>
                                <th width="100px">Total Stock</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map((item, idx) => (
                                <tr id="addr1" key={idx}>
                                    <td>
                                        <TextField type="text" name="product_name"
                                                   value={titleCase(item.product_name)}
                                                   onChange={(event) => handleRetrieveDrugs(event, idx)}
                                        />
                                        {(submitted && !item.product_name) &&
                                        <div className="invalid-text">Please enter the product name</div>}
                                        <Form.Control type="text" name="product_id"
                                                      value={item.product_id} style={{display: "none"}}
                                                      autocomplet="off" readOnly/>
                                        {item.showProduct ?
                                            <Form.Control as="select" multiple className='drop-down'>
                                                {allDrugs.map((product, index) =>
                                                    <option key={index} value={product.value}
                                                            onClick={() => handleClickDrug({
                                                                value: product.value,
                                                                innerText: product.label
                                                            }, idx)}>{product.label}</option>)}
                                            </Form.Control> : null}
                                    </td>
                                    <td>
                                        <SelectInput value={item.batch_id}
                                                     onChange={(e) => handleChangeBatch(e, idx)}
                                                     options={item.batches.map((batch) => ({
                                                         value: batch,
                                                         text: batch
                                                     }))} defaultOption="Select batch..."/>
                                        {(submitted && !rows[idx].batch_id) &&
                                        <div className="invalid-text">Batch ID is required</div>}
                                    </td>
                                    <td>
                                        <TextField type="text" readOnly
                                                   name="available_stock" placeholder="0"
                                                   value={item.available_stock}/>
                                    </td>
                                    <td>
                                        <TextField type="number"
                                            name="adjustment_quantity"
                                            value={item.adjustment_quantity}
                                            onChange={(e) => handleChangeQuantity(e, idx)}
                                            placeholder="0"
                                        />
                                        {(submitted && !rows[idx].adjustment_quantity) ?
                                            <div className="invalid-text">Please enter the quantity</div> :
                                            (rows[idx].adjustment_type === 'D' && rows[idx].adjustment_quantity > rows[idx].available_stock) &&
                                            <div className="invalid-text">You can only decrement up
                                                to {rows[idx].available_stock} item(s)</div>}
                                    </td>
                                    <td>
                                        <SelectInput name="adjustment_type" value={item.adjustment_type}
                                                     onChange={(e) => handleChangeAdjustmentType(e, idx)}
                                                     options={[{
                                                         value: 'I',
                                                         text: 'Increment'
                                                     }, item.available_stock > 0 ? {
                                                         value: 'D',
                                                         text: 'Decrement'
                                                     } : null].filter(item => Boolean(item))}
                                                     defaultOption='Select...'/>
                                        {(submitted && !rows[idx].adjustment_type) &&
                                        <div className="invalid-text">Please enter the adjustment type</div>}
                                    </td>
                                    <td>
                                        <TextField
                                            type="number"
                                            disabled={true}
                                            name="total_stock"
                                            value={item.total_stock}
                                            placeholder="0"
                                        />
                                    </td>
                                    <td>
                                        <button type="button" className="btn pharmacy-btn-dismiss btn-sm"
                                                onClick={() => handleRemoveSpecificRow(idx)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                            }
                            <tr>
                                <td colSpan={7}>
                                    <button type="button" className="btn pharmacy-gray-btn btn-sm"
                                            onClick={handleAddRow}>Add New Item
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <button className="btn pharmacy-btn" type='submit'
                                disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>

                    </div>
                </form>
            </Card>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustment);
