import React, {useEffect, useRef, useState} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {titleCase} from "../../Utils/titleCaseFunction";
import {Link} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import {convertGmtTime} from "../../Utils/titleCaseFunction";
import axios from "axios";
import config from "../../Helpers/config.json";
import {errorMessages} from "../../Helpers/ErrorMessages";
import * as snackbarActions from '../../Redux/Actions/SnackbarActions/SnackbarActions'
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import companyAddress from "../../Helpers/companyAddress.json"


const PosInvoiceDetails = ({actions, snackbars, match: {params}}) => {
    const [invoice, setInvoice] = useState({});
    const [loading, setLoading] = useState('idle')
    const {invoice_id} = params;
    const componentRef = useRef();

  

    useEffect(() => {
        setLoading('pending')
        axios.get(`${config.epharmUrl}/Cinvoice/pos_invoice_inserted_data`, {params: {invoice_id}}).then(response => {
            const data = response.data;
            const dt = data ? data : {};
            setInvoice(dt)
            setLoading('resolved')
        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('rejected')
        })
    }, [actions, invoice_id])

    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackBar();
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'resolved';
    const isError = loading === 'rejected';

    const {variant, message, open} = snackbars;
    const pos = !invoice ? {} : invoice;
    const invoice_all_data = !pos.invoice_all_data ? [] : pos.invoice_all_data;
    const {user_first_name, user_last_name} = pos;
    const user_name = titleCase(`${!user_first_name ? "" : user_first_name} ${!user_last_name ? "" : user_last_name}`);
    return (
        <div>
            <ReusableDashboardHeader component="POS Receipt" heading="Invoice"
                                     subHeading="POS Receipt" link={history.location.pathname}/>
            <PharmacySnackbar open={open} variant={variant} message={message} handleCloseBar={handleCloseSnackbar}/>
           
                {isLoading ? <div className="text-center mt-5"><img src="/images/listPreloader.gif"alt="loader"
                                    style={{width: '10%'}}/></div>:isSuccess ? 
                <div className="pos-invoice-style">
                    <div className="text-right mr-5">
                        <ReactToPrint
                            trigger={() => <button className="btn pharmacy-info-btn mb-2 mr-2">Print</button>}
                            content={() => componentRef.current}
                        />
                        <Link to="/createposinvoice">
                            <button className="btn pharmacy-btn-dismiss  mb-2">Cancel</button>
                        </Link>
                    </div>
                <div className="card styled-pos-card" ref={componentRef} id="printableArea">
                    <table border="0">
                        <tbody>
                        <tr>
                            <td>
                                <table border="0" width="100%">
                                    <tr>
                                        <td align="center" style={{borderBottom: "0.13rem #333 solid"}}>
                                            <span><img src="/images/mpeke.png"
                                                       alt="mpeke" style={{width: 120}}/></span><br/>
                                            {companyAddress.company_name}<br/>
                                           {companyAddress.address1}<br/>
                                            {companyAddress.address2}<br/>
                                            Email:  {companyAddress.email}<br/>
                                            Mobile: {companyAddress.mobile}<br/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <p><strong>{titleCase(pos.customer_name)}</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <p>Date:{pos.final_date ? convertGmtTime(pos.final_date) : ''}</p></td>
                                    </tr>
                                </table>
                                <table className="table table-borderless table-sm" width="100%">
                                    <tr>
                                        <td width=""><strong>Medicine</strong></td>
                                        <td width=""><strong>Qty</strong></td>
                                        {/* <td width=""><strong>Dosage</strong></td> */}
                                        <td width=""><strong>Price</strong></td>
                                        <td width=""><strong>Discount(%)</strong></td>
                                        <td width=""><strong>Total</strong></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{borderTop: "#333 0.06rem solid"}}>
                                        </td>
                                    </tr>
                                    {invoice_all_data && invoice_all_data.map((info, index) => (
                                        <tr key={index}>
                                            <td>{titleCase(info.product_name)}</td>
                                            <td>{info.quantity}</td>
                                            {/* <td >{info.dosage}</td> */}
                                            <td>{info.rate}</td>
                                            <td align='center'>{info.discount}</td>
                                            <td>{info.total_price}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="5" style={{borderTop: "#333 0.06rem solid"}}>
                                        </td>
                                    </tr>
                                    <tr>
                                        {/*<td align="left"/>*/}
                                        <td colSpan="4" align="left">Sub Total</td>
                                        <td>{pos.subTotal_ammount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" style={{borderTop: "#333 0.06rem solid"}}>
                                        </td>
                                    </tr>
                                    <tr>
                                        {/*<td align="left"/>*/}
                                        <td colSpan="4" align="left">Total Discount</td>
                                        <td>{pos.total_discount}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="5" style={{borderTop: "#333 0.06rem solid"}}>
                                        </td>
                                    </tr>
                                    <tr>
                                        {/*<td align="left"/>*/}
                                        <td colSpan="4" align="left">Grand Total</td>
                                        <td>{pos.total_amount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" style={{borderTop: "#333 0.06rem solid"}}>
                                        </td>
                                    </tr>
                                    <tr>
                                        {/*<td align="left"/>*/}
                                        <td colSpan="4" align="left">Paid</td>
                                        <td>{pos.paid_amount}</td>
                                    </tr>
                                    <tr>
                                        {/*<td align="left"/>*/}
                                        <td colSpan="4" align="left">Due</td>
                                        <td>{pos.due_amount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" style={{borderTop: "#333 0.06rem solid"}}>
                                        </td>
                                    </tr>
                                </table>
                                <table width="100%">
                                    <tr>
                                        <td> Receipt NO: {pos.invoice_no}</td>
                                        <td>User: {user_name}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> </div> : isError ? <div className="text-center">The server didnot return a valid response</div>:null}
        </div>
    )

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PosInvoiceDetails);
