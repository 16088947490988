import React from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form"
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as purchaseActions from "../../Redux/Actions/PurchaseActions/PurchaseActions"
import * as manufacturerActions from "../../Redux/Actions/ManufacturerActions/ManufacturerActions";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import {titleCase} from "../../Utils/titleCaseFunction";
import CsvDialog from "../../Utils/CsvDialog";
import {CSVLink} from "react-csv";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import {useAddPurchase} from "./CustomHooks/useAddPurchase";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import SelectInput from '../../Utils/FormInputs/SelectInput';


const AddPurchase = ({actions, manufacturers, purchases, snackbars, match}) => {
    const {local_purchase_order_id, purchase_id} = match.params
    const {
        rows, manufacturer_name: name, state, grand_total, openDialog, submitted,
        isSubmitted, allPurchases, handleChangeValue, purchaseCsv,
        handleSubmitPurchase, handleChangeManufacturer,
        handleChange, retrieveExpiry, handleAddRow, handleRemoveSpecificRow, handleSearchProduct, retrieveProductData,
        handleChangeQuantity, handleChangePrice, handleCloseBar, handleOpenDialog, handleCloseDialog, handleUploadCsv,
        handleChangePurchaseCsv, fetchData, csvLink, submitCsv
    } = useAddPurchase(actions, {local_purchase_order_id, purchase_id})
    const {purchase_date, invoice_no, details,payment_type} = state;
    const {all_manufacturers} = manufacturers;
    const manufs = !all_manufacturers ? [] : all_manufacturers;
    const {allProducts} = purchases;
    const manufProducts = !allProducts ? [] : allProducts;
    const {open, message, variant} = snackbars;
    const title = purchase_id ? 'Edit Purchase' : 'New Purchase';
    const route = purchase_id ? `/editpurchase/${purchase_id}` :
        local_purchase_order_id ? `/addpurchase/${local_purchase_order_id}`:'/addpurchase'
    return (
        <div className='journals'>
            <ReusableDashboardHeader component={title} heading={title}
                                     subHeading={title} link={route}/>
            <CsvDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} purchase
                       handleUploadCsv={handleUploadCsv} handleChangeUpload={handleChangePurchaseCsv}
                       message="Purchase" submitted={submitCsv} purchaseCsv={purchaseCsv}>
                    <span>
                        <button onClick={fetchData}
                                className="btn pharmacy-primary-btn">Download Sample File</button>
                    <CSVLink
                        data={allPurchases}
                        ref={csvLink}
                        filename="Purchases.csv"
                        className="hidden"
                    />
                    </span>
            </CsvDialog>

            <div className="general-ledger-header">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={8}>
                        {!(purchase_id || local_purchase_order_id) ?
                            <button className="btn pharmacy-info-btn btn-sm mx-3" onClick={handleOpenDialog}>Upload CSV
                                Purchase
                            </button> : null}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div className="text-right">
                            <Link to='/managepurchase'>
                                <button className='btn btn-sm pharmacy-info-btn'>Purchases</button>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="mui-tables">
                <Card className="p-3">
                    <PharmacySnackbar open={open} message={message} variant={variant}
                                      handleCloseBar={handleCloseBar}/>
                    <form onSubmit={handleSubmitPurchase} autoComplete="off">
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Supplier<span
                                    className="text-danger asterics ml-1">*</span></Form.Label>
                                <CustomSelect
                                    placeholder="Select Supplier"
                                    options={manufs.map(manu => ({
                                        value: manu.manufacturer_id,
                                        label: titleCase(manu.manufacturer_name)
                                    }))}
                                    value={name}
                                    onChange={handleChangeManufacturer}
                                />
                                {(submitted && !name) &&
                                <div className="invalid-text">Please select a supplier</div>}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Purchase Date<span
                                    className="text-danger asterics ml-1">*</span></Form.Label>
                                <TextField name="purchase_date" value={purchase_date} type="date"
                                           onChange={handleChangeValue}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Invoice No<span
                                    className="text-danger asterics ml-1">*</span></Form.Label>
                                <TextField type="text" name="invoice_no" value={invoice_no}
                                           onChange={handleChangeValue}
                                />
                                {(submitted && !invoice_no) &&
                                <div className="invalid-text">Please enter the invoice number</div>}
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Details</Form.Label>
                                <Textarea as="textarea" name="details" value={titleCase(details)}
                                          onChange={handleChangeValue}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Payment Method</Form.Label>
                                <SelectInput disabled={true} value={payment_type} name="payment_type" onChange={handleChangeValue}
                                         options={[{value: 1, text: 'Cash'},
                                             {value: 3, text: 'Credit'}]}/>
                            </Form.Group>
                            <Form.Group as={Col}/>
                        </Form.Row>
                        <div className="table-responsive">
                            <table className="table table-bordered p-0">
                                <thead>
                                <tr>
                                    <th scope="col">Item Information<span
                                        className="text-danger asterics ml-1">*</span>
                                    </th>
                                    <th scope="col">Batch ID<span className="text-danger asterics ml-1">*</span>
                                    </th>
                                    <th scope="col">Expiry Date<span className="text-danger asterics ml-1">*</span>
                                    </th>
                                    <th scope="col">Stock</th>
                                    <th scope="col">Quantity<span className="text-danger asterics ml-1">*</span>
                                    </th>
                                    <th scope="col">Unit Cost</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows.map((item, idx) => (
                                    <>
                                        <tr id="addr1" key={idx}>
                                            <td>
                                                <TextField type="text" name="product_name"
                                                           value={titleCase(item.product_name)}
                                                           onChange={(event) => handleSearchProduct(event, idx)}
                                                />
                                                {((submitted && !item.product_name) || item.selectProduct) &&
                                                <div className="invalid-text">Please enter the product name</div>}
                                                <Form.Control type="text" name="product_id"
                                                              value={item.product_id} style={{display: "none"}}
                                                              autocomplet="off" readOnly/>
                                                {item.showProduct &&
                                                <Form.Control as="select" multiple className='drop-down'>
                                                    {manufProducts.map((product, index) =>
                                                        <option key={index} value={product.value}
                                                                onClick={() => retrieveProductData({
                                                                    innerText: product.label,
                                                                    value: product.value
                                                                }, idx)}>{product.label}</option>)}
                                                </Form.Control>}
                                            </td>
                                            <td>
                                                <TextField type='text' name="batch_id"
                                                           value={rows[idx].batch_id}
                                                           onChange={(e) => handleChange(e, idx)}
                                                           onBlur={() => retrieveExpiry(item.batch_id, idx)}/>
                                                {(submitted && !rows[idx].batch_id) &&
                                                <div className="invalid-text">Please enter the batch Id</div>}
                                            </td>
                                            <td>
                                                <TextField type="date" name="expiry_date"
                                                           disabled={item.disableExpiry}
                                                           value={rows[idx].expiry_date}
                                                           onChange={(e) => handleChange(e, idx)}/>
                                                {(submitted && !rows[idx].expiry_date) ?
                                                    <div className="invalid-text">Please select the expiry
                                                        date</div> :
                                                    (submitted && !rows[idx].expiry_date > purchase_date) &&
                                                    <div className="invalid-text">Expiry date should greater than
                                                        purchase date</div>}

                                            </td>
                                            <td>
                                                <TextField type="text" disabled={true}
                                                           name="total_product" placeholder="0.00"
                                                           value={item.total_product}/>
                                            </td>
                                            <td>
                                                <TextField type="number"
                                                    name="product_quantity"
                                                    value={rows[idx].product_quantity}
                                                    onChange={(e) => handleChangeQuantity(e, idx)}
                                                    placeholder="0.00"

                                                />
                                                {(submitted && !rows[idx].product_quantity) &&
                                                <div className="invalid-text">Please enter the product
                                                    quantity</div>}
                                                <Form.Control type="hidden"
                                                              value={!item.discount ? 0 : item.discount}
                                                              name="discount"/>
                                            </td>
                                            <td>
                                                <TextField type="number"
                                                           name="product_rate"
                                                           onChange={(e) => handleChangePrice(e, idx)}
                                                           value={item.product_rate}
                                                           placeholder="0.00"
                                                />
                                                {(submitted && !rows[idx].product_rate) &&
                                                <div className="invalid-text">Unit cost is required</div>}
                                            </td>

                                            <td>
                                                <TextField
                                                    type="number"
                                                    disabled={true}
                                                    name="total_price"
                                                    value={rows[idx].total_price}
                                                    placeholder="0.00"
                                                />
                                            </td>
                                            <td>
                                                <button type="button" className="btn pharmacy-btn-dismiss btn-sm"
                                                        onClick={handleRemoveSpecificRow(idx)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>

                                    </>
                                ))
                                }
                                <tr>
                                    <td colSpan={2}>
                                        <button type="button" className="btn pharmacy-grey-btn btn-sm"
                                                onClick={handleAddRow}>Add New Item
                                        </button>
                                    </td>
                                    <td colSpan={4} className="text-right">
                                        Grand Total:
                                    </td>
                                    <td>
                                        <TextField type="text" disabled={true} name='grand_total'
                                                   value={grand_total}/>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                            <button type="submit" className="btn pharmacy-btn"
                                    disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );

}

function mapStateToProps(state) {

    return {
        manufacturers: state.manufacturers,
        invoices: state.invoices,
        purchases: state.purchases,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            manufacturerActions: bindActionCreators(manufacturerActions, dispatch),
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            purchaseActions: bindActionCreators(purchaseActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchase);
