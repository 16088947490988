import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as invoiceActions from "../../Redux/Actions/InvoiceActions/PosInvoiceActions";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {titleCase} from "../../Utils/titleCaseFunction";
import Row from "react-bootstrap/Row";
import * as customerActions from "../../Redux/Actions/CustomerActions/CustomerActions";
import InvoicePrintDialog from "./InvoicePrintDialog";
import {removeTime} from "../../Utils/titleCaseFunction"
import {useCreateInvoice} from "./CustomHooks/useCreateInvoice";
import ReusableDialog from "../../Utils/Dialog/ReusableDialog";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import Card from "@material-ui/core/Card";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons/faAngleDoubleDown";


const CreatePosInvoice = ({actions, snackbars, invoices,match}) => {
    const {component, invoice_id} = match.params;
    const obj = {
        title:{
            'pos-invoice':'New POS Invoice',
            'new-invoice':'New Invoice',
            'update-invoice':'Update Invoice'
        },
        link:{
            'pos-invoice':'/createinvoice/pos-invoice',
            'new-invoice':'/createinvoice/new-invoice',
            'update-invoice':`/updateinvoice/update-invoice/${invoice_id}`
        }
    }
    const {
        showNewCustomer,
        selectCustomer,
        showCustomer,
        showTax,
        submitted,
        rows,
        customer,
        state,
        openDialog,
        isSubmitted,
        paytype,
        handleChangeFormFields,
        handleChangePaymentMethod,
        handleChange,
        handleChangeBatch,
        handleAddRow,
        handleRemoveSpecificRow,
        handleCloseDialog,
        handleSaveInvoice,
        handleCloseSnackbar,
        handleChangeQuantity,
        handleChangeDiscount,
        handleChangeBalance,
        handleShowTax,
        handleRetrieveCustomerInfo,
        handleClickCustomer,
        handleRetrieveDrugs,
        handleClickDrug,
        handleShowNewCustomer,
        handleCloseCustomer,
        submitNewCustomer,
        handleChangeCustomer,
        handlePrint,
        submitCustomer,
        isSubmitCustomer, grand_total_price,
        n_total, change, due_amount,
        paid_amount,  total_discount_amount,previous_amount
    } = useCreateInvoice(actions, match);
    const {customer_name_other, email, mobile, address, previous_balance, credit_limit} = customer;
    const {invoice_date, inva_details, invdcount} = state;
    const {customers, drugs} = invoices;
    const allCustomers = !customers ? [] : customers;
    const allDrugs = !drugs ? [] : drugs;
    const {open, variant, message} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const pos_invoice = !permissions.pos_invoice ? {} : permissions.pos_invoice;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component={obj['title'][component]} heading="Invoice"
                                     subHeading={obj['title'][component]} link={obj['link'][component]}/>
            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-md-7">
                        <h6 className="mx-2 mt-2">{obj['title'][component]}</h6>
                    </div>
                    <div className="col-md-5">
                        <div className="pos-buttons text-right">
                            {/*<Link to="/createinvoice" style={{textDecoration: "none", color: "inherit"}}>*/}
                            {/*    <button type="button" className="btn mr-2 pharmacy-btn">*/}
                            {/*        <FontAwesomeIcon icon={faPlus}/> New Invoice*/}
                            {/*    </button>*/}
                            {/*</Link>*/}
                            <Link to="/manageinvoice" style={{textDecoration: "none", color: "inherit"}}>
                                <button type="button" className="btn pharmacy-info-btn">
                                    <FontAwesomeIcon icon={faList}/> Manage Invoice
                                </button>

                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Card className="p-2">
                <PharmacySnackbar open={open} variant={variant} message={message}
                                  handleCloseBar={handleCloseSnackbar}/>
                <ReusableDialog handleClose={handleCloseCustomer} openDialog={showNewCustomer}
                                isSubmitted={isSubmitCustomer} handleSubmit={submitNewCustomer} width='sm'
                                message='New Customer'>
                    <Form.Group as={Row}>
                        <Label name='Name' type column sm={3}/>
                        <Col sm="8">
                            <TextField type='text' value={customer_name_other} name='customer_name_other'
                                       onChange={handleChangeCustomer}/>
                            {submitCustomer && !customer_name_other ? <div className="text-danger">Customer name is required</div> : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Mobile' column sm={3}/>
                        <Col sm="8">
                            <TextField type='text' value={mobile} name='mobile' onChange={handleChangeCustomer}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Label name='Email' column sm={3}/>
                        <Col sm="8">
                            <TextField type='email' value={email} name='email' onChange={handleChangeCustomer}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Label name='Address' column sm={3}/>
                        <Col sm="8">
                            <Textarea value={address} name='address' onChange={handleChangeCustomer}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Previous Balance' column sm={3}/>
                        <Col sm="8">
                            <TextField type='number' value={previous_balance} name='previous_balance'
                                       onChange={handleChangeCustomer}/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Label name='Credit Limit' column sm={3}/>
                        <Col sm="8">
                            <TextField type='number' value={credit_limit} name='credit_limit'
                                       onChange={handleChangeCustomer}/>
                        </Col>
                    </Form.Group>
                </ReusableDialog>
                <InvoicePrintDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog}
                                    print={handlePrint} cancel="/manageinvoice" message="Invoice"/>
                <form onSubmit={handleSaveInvoice} autoComplete="off">
                    <Form.Group as={Row}>
                        <Label name='Date' column sm={2}/>
                        <Col sm="4">
                            <TextField type='date' value={invoice_date} readOnly/>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Label name='Customer' column sm={2}/>
                        <Col sm="4">
                            <TextField type='text' value={selectCustomer} onChange={handleRetrieveCustomerInfo} disabled={invoice_id}/>
                            {showCustomer && <Form.Control as="select" multiple>
                                {allCustomers.map((customer, index) => (
                                    <option key={index}
                                            value={customer.value}
                                            onClick={()=>handleClickCustomer({value:customer.value, innerText:customer.label})}>{titleCase(customer.label)}</option>))}
                            </Form.Control>}
                        </Col>
                        {!invoice_id ? <Col sm="2">
                            <button type="button" className="btn pharmacy-btn btn-sm"
                                    onClick={handleShowNewCustomer}>New Customer
                            </button>
                        </Col>:null}
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name='Payment Method' column sm={2}/>
                        <Col sm="4">
                            <SelectInput value={paytype} onChange={handleChangePaymentMethod}
                                         options={[{value: 1, text: 'Cash'},
                                             // {value: 3, text: 'Credit'}
                                             ]}/>
                        </Col>
                    </Form.Group>
                    <table className="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <td width="15%">Item Information<span
                                className="text-danger asterics ml-1">*</span></td>
                            <td>Batch<span className="text-danger asterics ml-1">*</span></td>
                            <td>Ava.Qty</td>
                            <td>Expiry</td>
                            <td>Unit</td>
                            <td>Qty<span className="text-danger asterics ml-1">*</span></td>
                            <td>Price</td>
                            <td>Discount%</td>
                            <td width="15%">Total</td>
                            <td>Action</td>

                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td width="15%">
                                    <TextField type="text" name="product_name"
                                               value={titleCase(item.product_name)}
                                               onChange={(event) => handleRetrieveDrugs(event, idx)}
                                    />
                                    {item.showProduct && <Form.Control as="select" multiple className="drop-down">
                                        {allDrugs.map((drug, index) => (
                                            <option key={index} value={drug.value}
                                                    onClick={() => handleClickDrug({innerText:drug.label,value:drug.value}, idx)}>{titleCase(drug.label)}</option>
                                        ))}
                                    </Form.Control>}
                                    {(submitted && !item.product_name) &&
                                    <div className="invalid-text">Please enter the product name</div>}
                                </td>
                                <td width="8%">
                                    <Form.Control as="select" className='form__control'
                                                  value={rows[idx].batch_id}
                                                  onChange={(e) => handleChangeBatch(e, idx)}>
                                        <option value=''>Select...</option>
                                        {rows[idx].batch_info && rows[idx].batch_info.map((batch, index) => (
                                            <option value={batch} key={index}
                                                    data-key={batch}>{batch}</option>
                                        ))}
                                    </Form.Control>
                                    {(submitted && !rows[idx].batch_id) &&
                                    <div className="invalid-text">Enter the batch</div>}
                                </td>
                                <td>
                                    <TextField
                                        type="text"
                                        name="available_quantity"
                                        value={rows[idx].available_quantity}
                                        disabled={true}
                                        placeholder="0.00"
                                    />
                                </td>
                                <td width="10%">
                                    <TextField
                                        type="text"
                                        value={rows[idx].expiry ? removeTime(rows[idx].expiry) : ''}
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        type="text"
                                        name="unit"
                                        value={!rows[idx].unit ? '' : rows[idx].unit}
                                        onChange={(e) => handleChange(e, idx)}
                                        disabled={true}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        type="number"
                                        name="product_quantity"
                                        value={rows[idx].product_quantity}
                                        onChange={(e) => handleChangeQuantity(e, idx)}
                                        placeholder="0.00"

                                    />
                                    {(submitted && !rows[idx].product_quantity) &&
                                    <div className="invalid-text">Enter the quantity</div>}
                                </td>
                                <td>
                                    <TextField
                                        type="text"
                                        name="product_rate"
                                        value={item.product_rate}
                                        disabled={true}
                                        placeholder="0.00"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        type="number"
                                        name="discount"
                                        min="0"
                                        value={rows[idx].discount}
                                        onChange={(e) => handleChangeDiscount(e, idx)}
                                        placeholder="0.00"
                                    />
                                    <Form.Control type="hidden" value={rows[idx].tax}/>
                                </td>
                                <td>
                                    <TextField
                                        type="text"
                                        name="total_price"
                                        value={rows[idx].total_price}
                                        onChange={(e) => handleChange(e, idx)}
                                        placeholder="0.00"
                                        readOnly
                                    />
                                </td>
                                <td>
                                    <button type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={() => handleRemoveSpecificRow(idx)}
                                    >
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                    <input type="hidden"
                                           value={rows[idx].total_discount}

                                           className="total_discount dppr"
                                           name="total_discount"/>
                                </td>
                            </tr>
                        ))}

                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={6} rowSpan={2}>
                                <Form.Group>
                                    <Form.Label>Invoice Details</Form.Label>
                                    <TextField as="textarea" name="inva_details"
                                               value={inva_details}
                                               onChange={handleChangeFormFields}/>
                                </Form.Group>
                            </td>
                            <td className="text-right">Invoice Discount</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="text" value={invdcount} name="invdcount"
                                               onChange={handleChangeFormFields}
                                               disabled={true}
                                    />
                                </Form.Group>
                            </td>
                            <td>
                                <button type="button" onClick={handleAddRow}
                                        className="btn  pharmacy-info-btn btn-sm ">
                                    <FontAwesomeIcon icon={faPlus}/></button>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right"> Total Discount</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number"
                                               name="total_discount_amount"
                                               value={total_discount_amount}
                                               placeholder="0.00"
                                               readOnly
                                    />
                                </Form.Group>
                            </td>
                        </tr>
                        {showTax &&
                        <>
                            <tr>
                                <td colSpan={7} className="text-right"> Tax</td>
                                <td colSpan={2}>
                                    <Form.Group>
                                        <TextField type="text"/>
                                    </Form.Group>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={7} className="text-right"> VAT</td>
                                <td colSpan={2}>
                                    <Form.Group>
                                        <TextField type="text"/>
                                    </Form.Group>
                                </td>
                            </tr>
                        </>}
                        <tr className="d-none">
                            <td colSpan={7} className="text-right"
                            > Total
                                Tax
                            </td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="text" name="total_tax" value={0}/>
                                </Form.Group>
                            </td>
                            <td>
                                <button className="btn pharmacy-amber-btn" onClick={handleShowTax}>
                                    <FontAwesomeIcon icon={faAngleDoubleDown}/>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7} className="text-right">Grand Total</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number" name="grand_total_price"
                                               value={grand_total_price}
                                               placeholder="0.00" readOnly/>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7} className="text-right"> Previous</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number" name="previous_amount" placeholder="0.00"
                                               value={previous_amount} readOnly/>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7} className="text-right"> Net Total</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number" name="n_total" placeholder="0.00"
                                               value={n_total} readOnly/>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7} className="text-right"> Paid Amount<span
                                className="text-danger asterics ml-1">*</span></td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number" value={paid_amount}
                                               onChange={handleChangeBalance}
                                               name="paid_amount" placeholder="0.00"/>
                                    {(submitted && !paid_amount) &&
                                    <div className="invalid-text">Please enter the paid amount</div>}
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7} className="text-right"> Due</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number" name="due_amount" placeholder="0.00"
                                               value={due_amount} readOnly/>
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={7} className="text-right"> Change</td>
                            <td colSpan={2}>
                                <Form.Group>
                                    <TextField type="number" id="change" name="change" placeholder="0.00"
                                               value={change} readOnly/>
                                </Form.Group>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    <button type="submit" disabled={isSubmitted === 'pending'}
                            className={`btn pharmacy-btn ${!pos_invoice.create && 'disabled'}`}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit Invoice'}
                    </button>
                </form>
            </Card>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        invoices: state.invoices,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            invoiceActions: bindActionCreators(invoiceActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            customerActions: bindActionCreators(customerActions, dispatch)

        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePosInvoice);
