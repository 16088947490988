import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as dashboardActions from "../../Redux/Actions/DashboardActions/DashboardActions";
import * as userActions from "../../Redux/Actions/UserActions/UserActions"
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Sparklines, SparklinesLine, SparklinesSpots} from "react-sparklines";
import CountTo from "react-count-to";
import ModelPopUp from "./ModelPopUp";
import DashboardHeader from "./DashboardHeader";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons/faCaretUp";
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import Card from '@material-ui/core/Card'
import {useDashboardContent} from "./CustomHooks/useDashboardContent";
import {ReusableCreditTables, ReusableDashboardPaymentTables} from "./DashboardTables";
import {makeStyles} from "@material-ui/core/styles";
import {Legend, Area} from "recharts";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectInput from "../../Utils/FormInputs/SelectInput";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import ReusableAreaChart from "../../Utils/Charts/ReusableAreaChart";


const cashHeadData = [{id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'invoice', numeric: false, disablePadding: false, label: 'Invoice#'},
    {id: 'customer', numeric: false, disablePadding: false, label: 'Customer'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];
const debtorsHeadData = [
    {id: 'customer', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];



export const useStyles = makeStyles(() => ({
    orangeCard: {
        borderLeft: '3px solid #ff7907'
    },
    blueCard: {
        borderLeft: '2px solid #3fb8f1'
    },
    yellowCard: {
        borderLeft: '2px solid #ffc107'
    },
    card:{
        padding:'0.7rem',
        borderRadius:'10px',
        border:'1px solid #f1f2f7',
        marginBottom:'0.6rem'
    }
}));

const DashboardContent = ({actions, snackbars}) => {
    const {
        years, openDialog, remainingDays, openAlert, handleChangeDate, period, year, from_date,
        to_date, inputType, debtors, handleOpenDialog, handleCloseDialog, handleChangePeriod,
        handleChangeYear, handleCloseBar, expiredDrugs, outOfStockDrugs, customers, manufacturers,
        products, outOfStock, outOfDate, totalSales, salesAmount, purchaseAmount,  graphData,cashPayments,
        loading,page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, totals
    } = useDashboardContent(actions);
    const {message, open, variant} = snackbars;
    const classes = useStyles();
    const {total_purchases, total_sales} = totals
    return (
        <div>
            <DashboardHeader dashboard/>
            <section>
                <ModelPopUp openDialog={openDialog}
                            handleOpenDialog={handleOpenDialog}
                            handleCloseDialog={handleCloseDialog}
                            expiredDrugs={expiredDrugs}
                            outOfStock={outOfStockDrugs}
                />
                <PharmacySnackbar open={open} variant={variant} message={message}
                                  handleCloseBar={handleCloseBar}/>
                {openAlert && <div className="alert alert-danger text-center">
                    {remainingDays > 0 ? `Your license will expire in ${remainingDays} day(s)` :
                        "Your License has expired, contact Marascientific to renew it"}</div>}
                <div className="dashboard-container-content">
                    <div className="row progress-report-chart">
                        <div className="col-lg-7">
                            <div className="row dashboard-cards">
                                <div className="col">
                                    <Card className="p-2 card-item" classes={{root: classes.blueCard}}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="res-dashboard-text">
                                                    <CountTo to={customers} speed={3000}/>
                                                    <FontAwesomeIcon icon={faCaretUp} className="text-warning ml-1"
                                                                     style={{fontSize: "28px"}}/></h6>
                                                <h6 className="responsive-dashboard-text">Total Customers</h6>
                                            </div>
                                            <div className="col">
                                                <div className="sparline">
                                                    <Sparklines data={[5, 10, 5, 20, 8, 15]} color="#3fb8f1">
                                                        <SparklinesLine
                                                            style={{strokeWidth: 3, stroke: "#3fb8f1", fill: "none"}}/>
                                                    </Sparklines>
                                                </div>
                                            </div>
                                        </div>

                                    </Card>
                                </div>
                                <div className="col">
                                    <Card className="p-2 card-item" classes={{root: classes.orangeCard}}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="res-dashboard-text"><CountTo to={manufacturers}
                                                                                            speed={6000}/>
                                                    <FontAwesomeIcon icon={faCaretUp} className="text-warning ml-1"
                                                                     style={{fontSize: "28px"}}/></h6>
                                                <h6 className="responsive-dashboard-text">Total Suppliers</h6>
                                            </div>
                                            <div className="col">
                                                <Sparklines
                                                    data={[-8, 2, 4, 3, 5, 4, 3, 5, 5, 6, 3, 9, 7, 3, 5, 6, 9]}
                                                    color="#ff7907">
                                                    <SparklinesLine
                                                        style={{strokeWidth: 3, stroke: "#ff7907", fill: "none"}}/>
                                                </Sparklines>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card className="p-2 card-item" classes={{root: classes.orangeCard}}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="res-dashboard-text"><CountTo to={products} speed={3000}/>
                                                    <FontAwesomeIcon icon={faCaretUp} className="text-warning ml-1"
                                                                     style={{fontSize: "28px"}}/></h6>
                                                <h6 className="responsive-dashboard-text">Total Medicine</h6>
                                            </div>
                                            <div className="col">
                                                <Sparklines data={[0, 0, 0, 0, 0]}>
                                                    <SparklinesLine
                                                        style={{strokeWidth: 3, stroke: "#ff7907", fill: "none"}}/>
                                                </Sparklines>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <div className="row dashboard-cards">
                                <div className="col">
                                    <Card className="p-2 card-item" classes={{root: classes.yellowCard}}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="res-dashboard-text"><CountTo to={outOfStock}
                                                                                            speed={3000}/>
                                                    <FontAwesomeIcon icon={faCaretUp} className="text-warning ml-1"
                                                                     style={{fontSize: "28px"}}/></h6>
                                                <h6 className="responsive-dashboard-text">Out of Stock</h6>
                                            </div>
                                            <div className="col">
                                                <Sparklines data={[0, 0, 0, 0, 0, 0, 0, 0]} color="#ffc107">
                                                    <SparklinesLine
                                                        style={{strokeWidth: 3, stroke: "#ffc107", fill: "none"}}/>
                                                    <SparklinesSpots style={{fill: "none"}}/>
                                                </Sparklines>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card className="p-2 card-item" classes={{root: classes.blueCard}}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="res-dashboard-text"><CountTo to={outOfDate}
                                                                                            speed={3000}/>
                                                    <FontAwesomeIcon icon={faCaretUp} className="text-warning ml-1"
                                                                     style={{fontSize: "28px"}}/></h6>
                                                <h6 className="responsive-dashboard-text">Expired</h6>
                                            </div>
                                            <div className="col">
                                                <Sparklines data={[0, 0, 0, 0, 0, 0, 0, 0]} color="#3fb8f1">
                                                    <SparklinesLine
                                                        style={{strokeWidth: 3, stroke: "#3fb8f1", fill: "none"}}/>
                                                </Sparklines>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col">
                                    <Card className="p-2 card-item" classes={{root: classes.blueCard}}>
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="res-dashboard-text"><CountTo to={totalSales}
                                                                                            speed={4000}/>
                                                    <FontAwesomeIcon icon={faCaretUp} className="text-warning ml-1"
                                                                     style={{fontSize: "28px"}}/></h6>
                                                <h6 className="responsive-dashboard-text">Total Invoice</h6>
                                            </div>
                                            <div className="col">
                                                <Sparklines
                                                    data={[5, 10, 5, 20, 18, 20, 21, 15, 2, 1, 0, 35, 6, 27, 8, 19]}>
                                                    <SparklinesLine
                                                        style={{strokeWidth: 3, stroke: "#3fb8f1", fill: "none"}}/>
                                                </Sparklines>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <Card className="card-item px-2">
                                <h6 className="mb-3">Progress Report</h6>
                                <Form.Group as={Row}>
                                    <Col sm={3}>
                                        <SelectInput value={period} onChange={handleChangePeriod}
                                                        options={[{
                                                            value: 'yearly',
                                                            text: 'Yearly'
                                                        }, {value: 'monthly', text: 'Monthly'},
                                                            {value: 'custom', text: 'Custom'}]}/>
                                    </Col>
                                    {period !== 'today' ? <>
                                        {period === 'yearly' ?
                                            <Col sm={3}>
                                                <SelectInput options={years} value={year}
                                                                onChange={handleChangeYear}/>
                                            </Col> :
                                            <Col sm={5}>
                                                <Form.Group as={Row}>
                                                    {period === 'custom' ?
                                                        <Label name='From' column sm={2}
                                                                style={{textAlign: 'right'}}/> : null}
                                                    <Col sm={8}>
                                                        <TextField type={inputType[period]}
                                                                    onChange={handleChangeDate}
                                                                    value={from_date} name='from_date'/>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        }
                                    </> : null}
                                    {period === 'custom' ? <Col sm={4}>
                                        <Form.Group as={Row}>
                                            <Label name='To' column sm={2}/>
                                            <Col sm={10}>
                                                <TextField type='date' value={to_date}
                                                            onChange={handleChangeDate}
                                                            name='to_date'/>
                                            </Col>
                                        </Form.Group>
                                    </Col> : null}
                                </Form.Group>
                                {graphData <= 0 ? <div className="p-3 text-center">No statistics found</div>
                                    :
                                    // <div className='row'>
                                    //     <div className="col-lg-8">
                                    <div className="pl-2">
                                            <ReusableAreaChart
                                                height={350}
                                                label={{
                                                    value: `Amount in '000`,
                                                    angle: -90,
                                                    position: "left",
                                                }}
                                                xlabel={{
                                                    value: "Months/Date",
                                                    position: "bottom",
                                                    offset: 0,
                                                }}
                                                data={graphData}
                                                dataKey="date"
                                            >
                                                <Legend verticalAlign="top" height={36} align="right"
                                                        iconType="plainline"/>
                                                <defs>
                                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#3fb8f1" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#3fb8f1" stopOpacity={0}/>
                                                    </linearGradient>
                                                    <linearGradient id="colorPurchase" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#3BA039FF" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#3BA039FF" stopOpacity={0}/>
                                                    </linearGradient>
                                                </defs>
                                                <Area
                                                    type="monotoneX"
                                                    dataKey="total_sales"
                                                    name="Sales"
                                                    fill="url(#colorUv)"
                                                    stroke="#3fb8f1"
                                                />
                                                <Area
                                                    type="monotoneX"
                                                    dataKey="total_purchases"
                                                    name="Purchases"
                                                    fill="url(#colorPurchase)"
                                                    stroke="#3BA039FF"
                                                />
                                            </ReusableAreaChart>
                                        {/* // </div> */}

                                        {/* // </div> */}
                                        {/* // <div className="col-lg-4">
                                        //     <div className={classes.card}>
                                        //         <h6>Purchases</h6>
                                        //         <p style={{fontWeight:600}}>UGX {total_purchases}</p>
                                        //     </div>
                                        //     <div className={classes.card}>
                                        //         <h6>Sales</h6>
                                        //         <p style={{fontWeight:600}}>UGX {total_sales}</p>
                                        //     </div>
                                        // </div> */}
                                    </div>}
                            </Card>
                            <ReusableDashboardPaymentTables loading={loading} headData={cashHeadData} data={cashPayments}
                                                            title='Cash payments' page={page} handleChangePage={handleChangePage}
                                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                            rowsPerPage={rowsPerPage}/>
                        </div>
                        <div className="col-lg-5">
                            <Card className="card-item">
                                <h6 className="mx-2 mt-2 text-center">Today's Report</h6>
                                <div className="pharmacy-table  px-2">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-sm ">
                                            <thead>
                                            <tr className="border-tr">
                                                <th/>
                                                <th>Cash Amount</th>
                                                {/*<th>Credit Amount</th>*/}
                                                <th>Total Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Total Sales</td>
                                                <td>{salesAmount}</td>
                                                <td>{salesAmount}</td>
                                                {/*<td align='center'>{salesAmount}</td>*/}
                                            </tr>
                                            <tr>
                                                <td>Total Purchase</td>
                                                <td>{purchaseAmount}</td>
                                                <td>{purchaseAmount}</td>
                                                {/*<td align='center'>{purchaseAmount}</td>*/}
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card>
                            <ReusableCreditTables data={debtors} headData={debtorsHeadData} title='Debtors' loading={loading}
                                                  route='/creditcustomer' />
                            {/*<ReusableDashboardPaymentTables loading={loading} headData={accrualsHeadData} data={[]}*/}
                            {/*                                title='Accruals'/>*/}
                        </div>

                    </div>
                    <div className="row progress-report-chart">


                    </div>
                </div>
            </section>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        statistics: state.dashboard,
        users: state.users,
        snackbars: state.snackbars
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            dashboardActions: bindActionCreators(dashboardActions, dispatch),
            userActions: bindActionCreators(userActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContent);
