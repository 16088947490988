import React, {Component} from "react";
import {stableSort, getSorting} from "../../Utils/Tables/MuiTables";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as stockActions from "../../Redux/Actions/StockActions/StockActions";
import {exportPDF} from "../../Utils/exportPDF";
import {copyToClipboard} from "../../Utils/copyToClipboard";
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions"
import PharmacySnackbar from "../../Utils/Snackbars/SnackbarUtil";
import axios from "axios";
import config from "../../Helpers/config";
import {errorMessages} from "../../Helpers/ErrorMessages";
import {titleCase} from "../../Utils/titleCaseFunction";
import {CSVLink} from "react-csv"
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import ReactExport from "react-data-export";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Tabs/ReusableTabs";
import {CustomPagination, EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'medicineName', numeric: false, disablePadding: false, label: 'Medicine Name'},
    {id: 'medicineType', numeric: false, disablePadding: false, label: 'Medicine Type'},
    {id: 'inQuantity', numeric: false, disablePadding: false, label: 'In Quantity'},
    {id: 'soldQuantity', numeric: false, disablePadding: false, label: 'Sold Quantity'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];

class StockReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            term: "",
            order: 'asc',
            orderBy: 'person',
            page: 0,
            rowsPerPage: 10,
            pages: [],
            pg: 1,
            records: 10,
            stockList: [],
            total_stock_amount: 0,
            total_purchase_amount: 0,
            total_sale_amount: 0,
            loading: false,
            search_value: '',
            stock_count: 0,
            stockPrint: [],
            period_value: '',
            sort_value: 'desc',
            from_date: '',
            to_date: ''
        };
        this.handler = this.handler.bind(this);
        this.ref = React.createRef();
        this.csvLink = React.createRef();
        this.excelLink = React.createRef();
        this.printRef = React.createRef();
    }


    calculateTotalStock = () => {
        const {stockList} = this.state;
        const allStock = !stockList ? [] : stockList;
        const totalStock = allStock.reduce((a, stock) => {
            return +(a + stock.stok_quantity);

        }, 0);
        const totalPurchase = allStock.reduce((a, stock) => {
            return +(a + (stock.purchase_p * stock.totalPurchaseQnty))
        }, 0);
        const totalSale = allStock.reduce((a, stock) => {
            //const sale = !stock.total_sale_price ? 0 : stock.total_sale_price;
            return +(a + (stock.sales_price * stock.totalSalesQnty))
        }, 0);
        this.setState({
            total_stock_amount: totalStock,
            total_purchase_amount: totalPurchase,
            total_sale_amount: totalSale
        })
    };
    handleChange = (event) => {
        this.setState({search_value: event.target.value}, () => {
            this.retrieveReport()
        })
    };

    retrieveReport = () => {
        const {actions} = this.props;
        let formData = new FormData();
        const {search_value, sort_value, pg} = this.state;
        formData.append("draw", 1);
        formData.append("columns[0][data]", "sl");
        formData.append("columns[0][name]", "");
        formData.append("columns[0][searchable]", "true");
        formData.append("columns[0][orderable]", false);
        formData.append("columns[0][search][value]", "");
        formData.append("columns[0][search][regex]", false);
        formData.append("columns[1][data]", "product_name");
        formData.append("columns[1][name]", "");
        formData.append("columns[1][searchable]", true);
        formData.append("columns[1][orderable]", true);
        formData.append("columns[1][search][value]", "");
        formData.append('columns[1][search][regex]', false);
        formData.append("columns[2][data]", "manufacturer_name");
        formData.append("columns[2][name]", "");
        formData.append("columns[2][searchable]", true);
        formData.append("columns[2][orderable]", false);
        formData.append("columns[2][search][value]", "");
        formData.append("columns[2][search][regex]", false);
        formData.append("columns[3][data]", "product_model");
        formData.append('columns[3][name]', "");
        formData.append("columns[3][searchable]", true);
        formData.append("columns[3][orderable]", false);
        formData.append("columns[3][search][value]", "");
        formData.append("columns[3][search][regex]", false);
        formData.append("columns[4][data]", "sale_price");
        formData.append("columns[4][name]", "");
        formData.append("columns[4][searchable]", true);
        formData.append("columns[4][orderable]", false);
        formData.append("columns[4][search][value]", "");
        formData.append("columns[4][search][regex]", false);
        formData.append("columns[5][data]", "purchase_p");
        formData.append("columns[5][name]", "");
        formData.append("columns[5][searchable]", true);
        formData.append("columns[5][orderable]", false);
        formData.append("columns[5][search][value]", "");
        formData.append("columns[5][search][regex]", false);
        formData.append("columns[6][data]", "totalPurchaseQnty");
        formData.append("columns[6][name]", "");
        formData.append("columns[6][searchable]", true);
        formData.append("columns[6][orderable]", false);
        formData.append("columns[6][search][value]", "");
        formData.append("columns[6][search][regex]", false);
        formData.append("columns[7][data]", "totalSalesQnty");
        formData.append("columns[7][name]", "");
        formData.append("columns[7][searchable]", true);
        formData.append("columns[7][orderable]", false);
        formData.append("columns[7][search][value]", "");
        formData.append("columns[7][search][regex]", false);
        formData.append("columns[8][data]", "stok_quantity");
        formData.append("columns[8][name]", "");
        formData.append("columns[8][searchable]", true);
        formData.append("columns[8][orderable]", false);
        formData.append("columns[8][search][value]", "");
        formData.append("columns[8][search][regex]", false);
        formData.append("columns[9][data]", "total_sale_price");
        formData.append("columns[9][name]", "");
        formData.append("columns[9][searchable]", true);
        formData.append("columns[9][orderable]", false);
        formData.append("columns[9][search][value]", "");
        formData.append("columns[9][search][regex]", false);
        formData.append('columns[10][data]', "purchase_total");
        formData.append("columns[10][name]", "");
        formData.append("columns[10][searchable]", true);
        formData.append("columns[10][orderable]", false);
        formData.append("columns[10][search][value]", "");
        formData.append("columns[10][search][regex]", false);
        formData.append("order[0][column]", 7);
        formData.append("order[0][dir]", sort_value);
        formData.append("start", pg);
        formData.append("length", 10);
        formData.append("search[value]", search_value);
        formData.append("search[regex]", false);
        this.setState({loading: true});
        axios.post(config.epharmUrl + "/Creport/checkList", formData).then(response => {
            const data = response.data.aaData;
            const stock_count = response.data.iTotalRecords;
            this.setState({stockList: data, stock_count: stock_count, loading: false}, () => {
                this.calculateTotalStock();
            });


        }).catch(error => {
            errorMessages(error, null, actions)
            this.setState({loading: false})
        })
    };

    componentDidMount() {
        this.retrieveReport();
        this.getData();
    }

    handler(value) {
        this.setState({term: value})
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0});
    };
    downloadPDF = () => {
        const {stockPrint, total_stock_amount, total_purchase_amount, total_sale_amount} = this.state;
        const allStock = !stockPrint ? [] : stockPrint;
        exportPDF("Stock Report", [["SL", "Medicine Name", "Medicine Type", "InQuantity",
                "Sold Quantity", "Stock"]],
            allStock.map((stock, index) => [index += 1, stock.product_name, stock.manufacturer_name,
                stock.product_model, stock.unit, stock.price, stock.manufacturer_price, stock.totalpurchaseqnty,
                stock.totalsalesqnty, stock.stock, stock.price * stock.totalsalesqnty,
                stock.manufacturer_price * stock.totalpurchaseqnty]),
            "Stock.pdf", [["", "", "", "", "", "", "", "Total:", "", total_stock_amount,
                total_sale_amount, total_purchase_amount]]);
    };
    copyTable = () => {
        const {actions} = this.props;
        const {rowsPerPage} = this.state;
        copyToClipboard(actions, rowsPerPage, this.printRef)
    };

    handleCloseBar = () => {
        const {actions} = this.props;
        actions.snackbarActions.hideSnackBar()
    };

    calculateAllStock = () => {
        const {stockPrint} = this.state;
        const allStock = !stockPrint ? [] : stockPrint;
        const totalStock = allStock.reduce((a, stock) => {
            return +(a + stock.stock);

        }, 0);
        const totalPurchase = allStock.reduce((a, stock) => {
            return +(a + (stock.manufacturer_price * stock.totalpurchaseqnty))
        }, 0);
        const totalSale = allStock.reduce((a, stock) => {
            return +(a + (stock.price * stock.totalsalesqnty))
        }, 0);
        this.setState({
            total_stock_amount: totalStock,
            total_purchase_amount: totalPurchase,
            total_sale_amount: totalSale
        })
    };

    handleGetPdf = () => {
        const {actions} = this.props;
        axios.get(config.epharmUrl + "/Creport/getallstock").then(response => {
            const data = response.data.stock_list;
            this.setState({stockPrint: data}, () => {
                this.calculateAllStock();
                this.downloadPDF()
            });
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };
    fetchData = () => {
        const {actions} = this.props;
        axios.get(config.epharmUrl + "/Creport/getallstock").then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const data = dt.stock_list ? dt.stock_list : [];
            this.setState({stockPrint: data}, () => this.csvLink.current.link.click());
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    getData = () => {
        const {actions} = this.props;
        axios.get(config.epharmUrl + "/Creport/getallstock").then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const data = dt.stock_list ? dt.stock_list : [];
            this.setState({stockPrint: data});
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    };

    handleChangeValue = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value}, () => {
            if (name === "sort_value") {
                this.retrieveReport();
            }
        })
    };
    submitSort = (event) => {
        event.preventDefault();
        this.retrieveReport();
    };

    handlePageChange = (pageNumber) => {
        this.setState({pg: pageNumber}, () => this.retrieveReport())
    };

    render() {
        const {order,  orderBy,  rowsPerPage, page, pg,  stockList, loading,
            search_value, stock_count, stockPrint, period_value, sort_value, from_date, to_date} = this.state;
        const allStock = !stockList ? [] : stockList;
        const {open, message, variant} = this.props.snackbars;
        const components = [{label: 'Stock Report', path: '/stockreport'},
            {label: 'Stock Report(Batch Wise)', path: '/stockbatchwise'}]
        return (
            <div className='journals'>
                <ReusableDashboardHeader component='Stock Report' heading="Stock"
                                         subHeading='Stock Report' link={history.location.pathname}/>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <div className='general-ledger-header pb-0'>
                    <form  onSubmit={this.submitSort}>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group as={Row}>
                                    <Label name='Sort by' sm={2} column/>
                                    <Col sm={9}>
                                        <SelectInput as="select" name="sort_value" value={sort_value}
                                                     onChange={this.handleChangeValue}
                                                     options={[{value: 'choose', text: 'Select...'},
                                                         {value: 'desc', text: 'Fast Moving Stock'}, {
                                                             value: 'asc',
                                                             text: 'Slow Moving/Dormant Stock'
                                                         }]}/>
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group as={Row}>
                                    <Label name='Period' sm={2} column/>
                                    <Col sm={9}>
                                        <SelectInput as="select" name="period_value" value={period_value}
                                                     onChange={this.handleChangeValue}
                                                     options={[{value: 'choose', text: 'Select...'},
                                                         {value: 'daily', text: 'Daily'}, {
                                                             value: 'dateRange',
                                                             text: 'Date Range'
                                                         }]}/>
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                {(period_value === "daily" || period_value === "dateRange" || period_value === "weekly" ||
                                    period_value === "monthly") &&
                                        <Form.Group as={Row}>
                                            {/*<Form.Label>{period_value === "dateRange" ? "Start Date" : period_value === "daily" ? "Date" :*/}
                                            {/*    period_value === "weekly" ? "Week" : period_value === "monthly" && "Month"}</Form.Label>*/}
                                            <Col sm='5'>
                                                <TextField
                                                    type="date"
                                                    name="from_date" value={from_date} onChange={this.handleChangeValue}/>
                                            </Col>
                                            <Col sm='5'>
                                                {period_value === "dateRange" ? <TextField
                                                    type="date"
                                                    name="to_date" value={to_date} onChange={this.handleChangeValue}/>:
                                                    <button type="submit" className=" btn pharmacy-btn mx-2">Sort</button>}
                                            </Col>
                                            <Col sm='2'>
                                                {period_value === "dateRange" ? <button type="submit" className=" btn pharmacy-btn mx-2">Sort</button>:null}
                                            </Col>

                                        </Form.Group>}
                            </div>
                        </div>
                    </form>
                </div>
                <PharmacySnackbar open={open} message={message} variant={variant}
                                  handleCloseBar={this.handleCloseBar}/>
                <MuiPaper>
                    <EnhancedTableToolbar title='Stock Report'>
                             <span className="btn-group" style={{flex: '1 1 100%'}}>
                                   <span>
                                <button className="btn btn-sm pharmacy-btn mr-1" onClick={this.fetchData}>CSV</button>

                                <CSVLink
                                    data={stockPrint}
                                    filename="stock.csv"
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank"
                                />
                            </span>
                            <ExcelFile filename="Stock Report" element={
                                <span><button className="btn btn-sm pharmacy-btn mr-1">Excel</button></span>
                            }>
                                <ExcelSheet data={stockPrint} name="Stock">
                                    <ExcelColumn label="Product Name" value="product_name"/>
                                    <ExcelColumn label="Product Type" value="product_model"/>
                                    <ExcelColumn label="InQuantity" value="totalpurchaseqnty"/>
                                    <ExcelColumn label="Sold Quantity" value="totalsalesqnty"/>
                                    <ExcelColumn label="Stock" value="stock"/>
                                </ExcelSheet>
                            </ExcelFile>
                            <span><button className="btn btn-sm pharmacy-btn mr-1"
                                          onClick={this.handleGetPdf}>Pdf</button></span>
                            </span>
                        <Form.Control type="text" value={search_value ? titleCase(search_value) : ''}
                                      onChange={this.handleChange}
                                      className='form__control'
                                      placeholder="Search" style={{float: 'right'}}/>
                    </EnhancedTableToolbar>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="6" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow></TableBody> : allStock.length > 0 ?
                            <TableBody>
                                {stableSort(allStock, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((d, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell component="th" scope="row" padding="default"
                                                >
                                                    <span>{d.sl}</span>
                                                </TableCell>
                                                <TableCell>
                                                                <span>
                                                                    {titleCase(d.product_name)}
                                                                </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.product_model}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.totalPurchaseQnty}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.totalSalesQnty}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{d.stok_quantity}</span>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>

                            : <TableBody><TableRow>
                                <TableCell colSpan="6" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                    <CustomPagination data={allStock} records={10} activePage={pg} total_count={stock_count}
                                      handleChangeNextPage={this.handlePageChange}/>
                </MuiPaper>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        stock: state.stock,
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            stockActions: bindActionCreators(stockActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(StockReport);
