import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FilterResults from 'react-filter-search';
import ReactToPrint from "react-to-print";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {CustomPagination} from "../../Utils/Tables/CustomTable";
import axios from "axios";
import config from "../../Helpers/config"
import * as snackbarActions from "../../Redux/Actions/SnackbarActions/SnackbarActions";
import {titleCase} from "../../Utils/titleCaseFunction";
import ReusableDashboardHeader from "../../Containers/Dasboard/ReusableDashboardHeader";
import {EnhancedTableHead, EnhancedTableToolbar, MuiPaper} from "../../Utils/Tables/CustomTable";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "../../Utils/FormInputs/TextField";

const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'medicineName', numeric: false, disablePadding: false, label: 'Medicine Name'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'purchase', numeric: false, disablePadding: false, label: 'Sale Price'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'}
];

const ManufacturerSalesDetails = ({actions, snackbars}) => {
    const [sales, setSales] = useState([]);
    const [total_count, setTotalCount] = useState(0);
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(false);
    const componentRef = useRef();

    useEffect(() => {
        setLoading(true)
        axios.get(`${config.epharmUrl}/Cmanufacturer/manufacturer_sales_details_all/${activePage}`).then(response => {
            const data = response.data;
            const dt = !data ? {} : data;
            const ct = !dt.total_count ? {} : dt.total_count;
            const count = ct.count ? ct.count : 0;
            const arr = !dt.sales_info ? [] : dt.sales_info;
            setSales(arr);
            setTotalCount(count);
            setLoading(false)
        }).catch(error => {
            // errorMessages(error, null, actions)
            setLoading(false)
        })
    }, [activePage])


    const handler = (value) => {
        setTerm(value)
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber)
    };

    const data = !sales ? [] : sales;
    return (
        <div className='journals'>
            <ReusableDashboardHeader component="Sales by Supplier" heading="Supplier"
                                     subHeading="Sales by Supplier" link="/manufacturersales"/>
            <div className='general-ledger-header'>
                <h6 className='my-0'>Sales by Supplier</h6>
            </div>
            
            <MuiPaper>
                <EnhancedTableToolbar title=''>
                    <TextField type="text"
                               onChange={event => handler(event.target.value)}
                               placeholder="Search" style={{float: 'right'}}/>
                </EnhancedTableToolbar>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size='medium'
                        aria-label="enhanced table"
                        ref={componentRef}
                    >
                        <EnhancedTableHead headCells={headData}/>
                        {loading ? <TableBody><TableRow>
                            <TableCell colSpan="5" align="center"><img src="/images/listPreloader.gif"
                                                                       alt="loader"
                                                                       style={{width: '5%'}}/></TableCell>
                        </TableRow>
                        </TableBody> : data.length > 0 ?
                            <FilterResults
                                value={term}
                                data={data}
                                renderResults={results => (
                                    <TableBody>
                                        {results.slice(0, 10)
                                            .map((d, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        className='mui-table-row'>
                                                        <TableCell component="th" scope="row"
                                                                   padding="default"
                                                                   className='mui-table-cell'>
                                                            <span>{d.date}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                                <span><Link
                                                                    to={{pathname: `/medicinedetails/${d.product_id}`}}
                                                                    style={{color: "#09905c"}}>{titleCase(d.product_name)}</Link></span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.quantity}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.manufacturer_rate}</span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span>{d.total}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                )}
                            />
                            : <TableBody><TableRow>
                                <TableCell colSpan="5" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </Table>
                </TableContainer>
                <CustomPagination data={data} records={10} activePage={activePage}
                                  total_count={total_count}
                                  handleChangeNextPage={handlePageChange}/>
            </MuiPaper>
        </div>
    )


}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbars
    }

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)

        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerSalesDetails);